import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CurrentLocaleService } from '@hawaiianair/core';
import { environment } from '~environments/environment';
import { FeatureFlagClientService } from '~app/services/feature-flag-client.service';
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';

@Injectable({
  providedIn: 'root'
})
export class SpaGuard implements CanActivate {
  constructor(
    public currentLocale: CurrentLocaleService,
    private featureFlagClient: FeatureFlagClientService,
  ) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.featureFlagClient.waitUntilReady()?.then(() => {
      if (this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.WEB_CHECK_IN_SPA_ENABLED)) {
        return true;
      }

      window.location.href = environment.spaDisabledUrls[this.currentLocale.locale] || environment.spaDisabledUrls['en-us'];
      return false;
    }) || false;
  }
}
