import { NgModule } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData, DatePipe, TitleCasePipe } from '@angular/common';
import { TaggingDataService } from '@hawaiianair/tagging';
import { CheckinTaggingDataService } from './services/checkin-tagging-data.service';

// so we can format dates and numbers and things
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeZh from '@angular/common/locales/zh';
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localeZh);

import { BrowserModule } from '@angular/platform-browser';
import { APP_ENVIRONMENT } from '@hawaiianair/common';
import { AnalyticsModule } from '@hawaiianair/analytics';
import { CoreModule } from '@hawaiianair/core';
import { HaHeaderModule } from '@hawaiianair/header';
import { HaFooterModule } from '@hawaiianair/footer';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppComponentsModule } from './components/app-components.module';
import { AppSharedModule } from '~shared/app.shared.module';
import { AppStoreModule } from './store/app-store.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CheckInInterceptor } from './services/checkin-interceptor';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { dynamicRoutes } from './app-dynamic.routes';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CheckinRouteSerializer } from './store/reducers/router/checkin-route-serializer';
import { ModalsModule } from './modals/modals.module';
import { environment } from '~environments/environment';
import { HaMemberBadgeModule } from '@hawaiianair/member-badge';
import { MemberProfileDispatchers } from '@hawaiianair/common-services';

@NgModule({
  imports: [
    // withServerTransition is needed to enable universal rendering
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    CommonModule,
    CoreModule.forRoot({
      dynamicRoutes
    }),
    AnalyticsModule.forRoot(),
    AppSharedModule.forRoot(),
    AppComponentsModule,
    ModalsModule,
    AppStoreModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot({ serializer: CheckinRouteSerializer }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    HaHeaderModule,
    HaMemberBadgeModule,
    HaFooterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule
  ],
  providers: [
    DatePipe,
    TitleCasePipe,
    MemberProfileDispatchers,
    // IMPORTANT: you must set the base href with this token, not a <base> tag in the HTML.
    { provide: APP_BASE_HREF, useValue: environment.deployRootPath },

    // the framework will pull configuration values from this object
    { provide: APP_ENVIRONMENT, useValue: environment },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: CheckInInterceptor,
      multi: true
    },

    { provide: TaggingDataService, useClass: CheckinTaggingDataService },

  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
