import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Subscription, Observable } from 'rxjs';
import { fadeAnimation } from '~app/animations/animations';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { AnalyticsDispatchers, ReferenceDataSelectors, SessionSelectors, TripDispatchers, TripSelectors } from '~app/store';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { PassengerList } from '~app/models/passengerlist.model';
import { Passenger } from '~app/models/passenger.model';
import { PassengerName } from '~app/models/passengername.model';
import { TripSearch } from '~app/models/trip-search.model';
import { take } from 'rxjs/operators';
import { ModalContentsService } from '~app/modals/modal-contents.service';

@Component({
  selector: 'app-group-passengers',
  templateUrl: './group-passengers.component.html',
  styleUrls: ['./group-passengers.component.scss'],
  animations: [
    fadeAnimation,
  ],
})

@ContentSchema({
  description: 'GroupPassengersComponent',
  name: 'GroupPassengers',
  props: [
    { name: 'addAnotherGuestText', type: PropertyType.Text },
    { name: 'addGuestDescription', type: PropertyType.Text },
    { name: 'addGuestText', type: PropertyType.Text },
    { name: 'defaultNavButtons', type: PropertyType.Object },
    { name: 'description', type: PropertyType.Text },
    { name: 'heading', type: PropertyType.Text },
    { name: 'internationalNavButtons', type: PropertyType.Object },
    { name: 'limitReachedText', type: PropertyType.Text },
    { name: 'paxFirstNameLabel', type: PropertyType.Text },
    { name: 'paxLastNameLabel', type: PropertyType.Text },
    { name: 'paxMiddleNameLabel', type: PropertyType.Text },
  ],
})

export class GroupPassengersComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  subscriptions: Subscription = new Subscription();
  tripData$: Observable<Response<Trip>>;
  loading$: Observable<boolean>;
  groupPassengerList$: Observable<PassengerList>;
  tripData: Response<Trip>;
  groupPassengersList: Passenger[];
  groupPassengersAddForm: UntypedFormGroup;
  showAddPaxForm: boolean;
  sessionTripSearch$: Observable<TripSearch>;
  airportLoading$: Observable<boolean>;

  constructor(
    private tripDispatchers: TripDispatchers,
    private tripSelectors: TripSelectors,
    private sessionSelectors: SessionSelectors,
    private modalContentsService: ModalContentsService,
    private fb: UntypedFormBuilder,
    private referenceDataSelectors: ReferenceDataSelectors,
    private analyticsDispatcher: AnalyticsDispatchers,
  ) {
    this.tripData$ = this.tripSelectors.trip$;
    this.loading$ = this.tripSelectors.tripLoading$;
    this.groupPassengerList$ = this.tripSelectors.groupPassengerList$;
    this.sessionTripSearch$ = this.sessionSelectors.sessionTripSearch$;
    this.airportLoading$ = this.referenceDataSelectors.airportLoading$;
  }

  ngOnInit() {
    this.setTripData();
    this.initializeFormGroup();
    this.setErrorModalContents();
    this.analyticsDispatcher.groupPassengersPageLoaded();
  }

  setTripData(): void {
    this.subscriptions.add(
      this.tripData$.subscribe(trip => {
        if (!!trip) {
          this.tripData = trip;
        }
      })
    );
    this.subscriptions.add(
      this.groupPassengerList$.subscribe(groupPassengerList => {
        if (!!groupPassengerList) {
          if (this.groupPassengersList !== groupPassengerList.entries) {
            this.groupPassengersList = groupPassengerList.entries;
            this.showAddPaxForm = false;
          }
        }
      })
    );
  }

  groupPassengerAdd(): void {
    const tripId = this.tripData.results[0].id;
    const confirmationCode = this.tripData.results[0].confirmationCode;
    const passengerName: PassengerName = {
      firstName: !!this.groupPassengersAddForm.get('paxMiddleName').value ?
      `${this.groupPassengersAddForm.get('paxFirstName').value} ${this.groupPassengersAddForm.get('paxMiddleName').value}` :
       this.groupPassengersAddForm.get('paxFirstName').value,
      lastName: this.groupPassengersAddForm.get('paxLastName').value,
    };
    this.tripDispatchers.getGroupPassengerByName(tripId, confirmationCode, passengerName);
  }

  addAnotherButtonClick() {
    this.showAddPaxForm = true;
    this.initializeFormGroup();
  }

  isMaxPassengers(): boolean {
    if (!!this.groupPassengersList) {
      return this.groupPassengersList.length === 7;
    }
  }

  initializeFormGroup() {
    this.groupPassengersAddForm = this.fb.group({
      paxFirstName: ['', [Validators.required]],
      paxMiddleName: [''],
      paxLastName: ['', [Validators.required]],
    });
    this.showAddPaxForm = true;
  }

  submitGroupBookingInformation(continueButtonClicked: Event) {
    if (!!this.groupPassengersList) {
        const passengerIds: string[] = this.groupPassengersList.map(passengerId => passengerId.id);
        let tripSearch: TripSearch;
        this.sessionTripSearch$.pipe(take(1)).subscribe(search => tripSearch = search);
        this.tripDispatchers.getByPassengerId(tripSearch, '', passengerIds);
    }
  }

  setErrorModalContents(): void {
    this.modalContentsService.addRendering('group-dob-modal', this.rendering.components['group-dob-modal']);
    this.modalContentsService.addRendering('add-guest-error-modal', this.rendering.components['add-guest-error-modal']);
}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
