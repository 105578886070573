import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { ModalsService } from '~app/modals/modals.service';
import { PassengerName } from '~app/models/passengername.model';
import { FeatureFlagClientService } from '~app/services/feature-flag-client.service';
import { InternationalDetailsSelectors } from '~app/store/services/international-details/international-details.selectors';

@Component({
  selector: 'app-passport-information',
  templateUrl: './passport-information.component.html',
  styleUrls: ['./passport-information.component.scss']
})
@ContentSchema({
  name: 'PassportInformation',
  description: 'PassportInformationComponent',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'scanPassport', type: PropertyType.Text },
    { name: 'scanPassportDescription', type: PropertyType.Text },
    { name: 'scanPassportDisclaimer', type: PropertyType.Text },
    { name: 'scanPassportComplete', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
  ],
  components: [
    'manual-passport-form'
  ]
})
export class PassportInformationComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;

  subs = new Subscription();
  loading$: Observable<boolean>;

  isSaveButtonEnabled = false;
  passengers: {
    id: string;
    passengerName: PassengerName;
    dateOfBirth?: string;
    gender: string;
    isPassportScanned: boolean;
  }[] = [];

  isManualPassportFormEnabled = false;

  constructor(
    private modalsService: ModalsService,
    private modalContentsService: ModalContentsService,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
    private featureFlagClient: FeatureFlagClientService,
  ) {
    this.loading$ = this.internationalDetailsSelectors.internationalDetailsLoading$;
  }

  ngOnInit(): void {
    this.modalContentsService.addRendering('passport-match-error-modal', this.rendering.components['passport-match-error-modal']);
    this.subs.add(
      combineLatest([
        this.internationalDetailsSelectors.internationalPassengers$,
        this.internationalDetailsSelectors.passportInformation$
      ]).subscribe(([paxes, passportInfo]) => {
        this.passengers = paxes?.map(pax => {
          return {
            ...pax,
            isPassportScanned: passportInfo.some(passport => passport.passengerId === pax.id)
          };
        });
        this.isSaveButtonEnabled = this.passengers.every(pax => !!pax?.isPassportScanned);
      })
    );

    this.isManualPassportFormEnabled = this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.WEB_CHECK_IN_MANUAL_PASSPORT_FORM);
  }

  scanPassport(paxId: string): void {
    this.modalsService.openScanPassport(paxId);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
