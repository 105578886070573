import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Subscription } from 'rxjs';
import { RegexPatterns } from '~app/constants/regex-constants';
import { ReferenceDataSelectors } from '~app/store';
import { CustomValidators } from '~app/validators/noMatch.validator';
import { CountryMap } from '~app/models/country.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-destination-information',
  templateUrl: './destination-information.component.html',
  styleUrls: ['./destination-information.component.scss'],
})

@ContentSchema({
  name: 'DestinationInformation',
  description: 'Destination Information Component',
  props: [
    { name: 'select', type: PropertyType.Text },
    { name: 'firstDayLocations', type: PropertyType.Object },
  ],
})

export class DestinationInformationComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  @Input() destinationInfoForm: UntypedFormGroup;
  subs = new Subscription();
  countriesList: string[];
  countryMap: CountryMap;

  constructor(
    private referenceDataSelectors: ReferenceDataSelectors,
  ) {
  }

  ngOnInit() {
    this.subs.add(this.destinationInfoForm.get('location').valueChanges.subscribe(val => {
      this.createDestinationForm(val);
    }));

    this.referenceDataSelectors.countries$.pipe(take(1)).subscribe((countries) => {
      this.countriesList = countries.countryDisplayName;
      this.countryMap = countries.countryMap;
    });
  }

  createDestinationForm(locationForm: { title: string, fields: Array<any> }): void {
    // remove current destinationForm from formGroup
    if (!!this.destinationInfoForm.get('destinationForm')) {
      this.destinationInfoForm.removeControl('destinationForm');
    }
    // create required form fields of destinationForm dynamically
    if (!!locationForm) {
      const group: any = {};
      locationForm.fields.forEach(field => {
        switch (field.id) {
          case 'addressLine1':
            group[field.id] = new UntypedFormControl('', [Validators.required, Validators.pattern(RegexPatterns.addressValidation), CustomValidators.characterCountError(RegexPatterns.addressValidation)]);
            break;
          case 'cityName':
            group[field.id] = new UntypedFormControl('', [Validators.required, Validators.pattern(RegexPatterns.cityValidation), CustomValidators.characterCountError(RegexPatterns.cityValidation)]);
            break;
          case 'stateCode':
            group[field.id] = new UntypedFormControl('', [Validators.pattern(RegexPatterns.stateCodeValidation), CustomValidators.characterCountError(RegexPatterns.stateCodeValidation)]);
            break;
          case 'zipCode':
            group[field.id] = new UntypedFormControl('', [Validators.required, Validators.pattern(RegexPatterns.zipCodeValidation), CustomValidators.characterCountError(RegexPatterns.zipCodeValidation)]);
            break;
          case 'countryCode':
            group[field.id] = new UntypedFormControl('', [Validators.required, CustomValidators.noMatch(Object.values(this.countryMap))]);
            break;
          default:
            group[field.id] = new UntypedFormControl('', [Validators.required]);
            break;
        }
      });
      this.destinationInfoForm.addControl('destinationForm', new UntypedFormGroup(group));
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
