<app-checkin-header [heading]="rendering.props['heading']" [description]="rendering.props['description']"></app-checkin-header>
<div class="background container">
    <mat-card class="mt-5 mat-elevation-z3">
        <div class="row">
            <div class="col-md-4 border-right-custom custom-border">
                <p class="subtitle-bold text-left" *haText="rendering.props['carryOnAndPersonalItems']['heading']">{{rendering.props['carryOnAndPersonalItems']['heading']}}</p>
                <p *haText="rendering.props['carryOnAndPersonalItems']['description']" class="body-copy text-left"></p>
                <ng-container id="carryonItems"
                    *ngFor="let result of rendering?.props['carryOnAndPersonalItems']['items']"
                    class="col-sm-4 text-center">

                    <p class="body-copy-bold text-left" *haText="result.subheading"></p>
                    <div class="row mb-3">
                        <div class="col-6 text-center" *ngFor="let subitem of result.item">
                            <div [class]="'mx-auto bag-advisory-icons icon-64 ' + subitem.icon"></div>
                            <div class="foot-note clearfix" *haText="subitem.title"></div>
                        </div>

                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-sm-12 foot-note text-secondary" *haRichText="rendering.props['carryOnAndPersonalItems']['footerdescription']"></div>
                </div>
            </div>
            <div class="col-md-4 border-right-custom custom-border"  *ngFor="let result of rendering?.props['checkinItems']['items']">
                <p class="subtitle-bold text-left" *haText="rendering.props['checkinItems']['heading']"></p>
                <p class="body-copy text-left" *haRichText="rendering.props?.checkinItems?.description"></p>
                <p class="body-copy-bold text-left" *haText="result.subheading"></p>

                <ng-container id="checkinItems"
                    class="col-sm-4 text-center">
                    <div class="row">
                      <div class="col-6 text-center">
                        <div [class]="'mx-auto bag-advisory-icons icon-64 ' + result?.suitcaseItem?.icon"></div>
                        <div class="foot-note clearfix" *haText="result?.suitcaseItem?.title"></div>
                      </div>

                  </div>
                    <div class="row">
                      <div class="col-sm-12 foot-note text-secondary footer-description"
                          *haRichText="rendering.props['checkinItems']['footerdescription']"></div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-center mb-5" *ngFor="let subitem of result.item">
                            <div [class]="'mx-auto bag-advisory-icons icon-64 ' + subitem.icon"></div>
                            <div class="foot-note clearfix" *haText="subitem.title"></div>
                        </div>

                    </div>
                </ng-container>
            </div>
            <div class="col-md-4">
                <p class="subtitle-bold text-left" *haText="rendering.props['specialItems']['heading']"></p>
                <p class="body-copy text-left" *haText="rendering.props['specialItems']['description']"></p>
                <ng-container id="specialItems" *ngFor="let result of rendering?.props['specialItems']['items']"
                    class="col-sm-4 text-center">
                    <p class="body-copy-bold text-left" *haText="result.subheading"></p>
                    <div class="row mb-3">
                        <div class="col-6 text-center mb-5" *ngFor="let subitem of result.item">
                            <div [class]="'mx-auto bag-advisory-icons icon-64 ' + subitem.icon"></div>
                            <div class="foot-note clearfix" *haText="subitem.title"></div>
                        </div>

                    </div>
                </ng-container>
            </div>
        </div>
    </mat-card>
</div>
<app-checkin-footer [navButtons]="rendering?.props['navButtons']"></app-checkin-footer>
