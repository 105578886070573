import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { AnalyticsDispatchers, ReferenceDataSelectors, SecurityInfoDispatchers, SecurityInfoSelectors } from '~app/store';
import { ModalsService } from '~app/modals/modals.service';
import { TravelDocument } from '~app/models/traveldocument.model';
import { SecurityInfo } from '~app/models/securityinfo.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { take } from 'rxjs/operators';
import { Countries } from "~app/store/reducers/reference-data/reference-data.reducer";
import {CountryName} from "~app/models/country.model";
import { UntypedFormControl, Validators } from "@angular/forms";
import { CustomValidators } from "~app/validators/noMatch.validator";
import { PassengerInfo } from '~app/models/passengerinfo.model';
import { TitleCasePipe } from '@angular/common';
import {RequiredInfo} from "~app/models/requiredinfo.model";
import {AddressType, ValidationType} from '~app/constants/international-constants';
import { ModalContentsService } from '~app/modals/modal-contents.service';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { Validation } from '~app/models/securityvalidation.model';
import { ISecurityInfoState } from '~app/store/reducers/security-info/security-info.reducers';

@Component({
  selector: 'app-international-traveler-information',
  templateUrl: './international-traveler-information.component.html',
  styleUrls: ['./international-traveler-information.component.scss']
})
@ContentSchema({
  name: 'InternationalTravelerInformation',
  description: 'InternationalTravelerInformationComponent',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'scanPassport', type: PropertyType.Text },
    { name: 'scanPassportDescription', type: PropertyType.Text },
    { name: 'scanPassportDisclaimer', type: PropertyType.Text },
    { name: 'scanPassportComplete', type: PropertyType.Text },
    { name: 'travelInfoTitle', type: PropertyType.Text },
    { name: 'sameTravelInformationCheckboxContent', type: PropertyType.Text },
    { name: 'contactInformationTitle', type: PropertyType.Text },
    { name: 'sameContactInformationCheckboxContent', type: PropertyType.Text },
    { name: 'firstDayDescription', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
    { name: 'residenceInformationTitle', type: PropertyType.Text },
    { name: 'countryOfResidenceLabel', type: PropertyType.Text },
    { name: 'residenceInfoComplete', type: PropertyType.Text },
    { name: 'emergencyInfoComplete', type: PropertyType.Text }
  ]
})
export class InternationalTravelerInformationComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  emergencyContactInfoRendering: ComponentRendering;
  travelInformationRendering: ComponentRendering;
  isEmergencyContactInfoFormValid = false;
  isTravelInformationFormValid = false;
  isSameTravelInfo = false;
  isSameEmergencyContact = false;
  travelDocuments$: Observable<TravelDocument[]>;
  defaultPassenger$: Observable<SecurityInfo>;
  passengerInfo$: Observable<PassengerInfo>;
  securityInfoLoading$: Observable<boolean>;
  requiredInfoSummary$: Observable<RequiredInfo[]>;
  countries$: Observable<Countries>;
  defaultPaxSecurityInfo: SecurityInfo;
  iSecurityInfoState: ISecurityInfoState;
  validations$: Observable<Validation[]>;
  canShowForm = true;
  securityInfo$: Observable<SecurityInfo[]>;
  countries: Countries;
  countriesList: CountryName[];
  countryOfResidence: UntypedFormControl;
  isDestinationFormRequired = false;
  currentPassengerId: PassengerInfo;
  subscriptions: Subscription = new Subscription();

  constructor(
    private securityInfoSelectors: SecurityInfoSelectors,
    private securityInfoDispatchers: SecurityInfoDispatchers,
    private modalsService: ModalsService,
    private referenceDataSelectors: ReferenceDataSelectors,
    private titleCasePipe: TitleCasePipe,
    private modalContentsService: ModalContentsService,
    private dynamicContentService: DynamicContentService,
    private analyticsDispatchers: AnalyticsDispatchers,
  ) {
    this.travelDocuments$ = this.securityInfoSelectors.travelDocuments$;
    this.defaultPassenger$ = this.securityInfoSelectors.defaultPassenger$;
    this.passengerInfo$ = this.securityInfoSelectors.passengerInfo$;
    this.securityInfoLoading$ = this.securityInfoSelectors.securityInfoLoading$;
    this.requiredInfoSummary$ = this.securityInfoSelectors.requiredInfoSummary$;
    this.countries$ = this.referenceDataSelectors.countries$;
    this.validations$ = this.securityInfoSelectors.validations$;
    this.securityInfo$ = this.securityInfoSelectors.securityInfo$;
  }

  ngOnInit() {
    this.modalContentsService.addRendering('passport-match-error-modal', this.rendering.components['passport-match-error-modal']);
    this.requiredInfoSummary$.subscribe((requiredInfo) => {
      if (requiredInfo) {
        this.isDestinationFormRequired = requiredInfo.some((required) => {
          return required.additionalInfo.code === ValidationType.DOCAD;
        });
        this.isTravelInformationFormValid = !this.isDestinationFormRequired;
      }
    });
    this.countries$.subscribe((countries) => {
      this.countries = countries;
      this.countriesList = countries.countryDisplayName;
    });
    this.emergencyContactInfoRendering = (this.rendering.components['emergencyContactInfo'] || [{}])[0];
    this.travelInformationRendering = (this.rendering.components['travelInformation'] || [{}])[0];

    // init page with checked checkboxes if !!defaultPax
    this.defaultPassenger$.pipe(take(1)).subscribe(defaultPax => {
      if (defaultPax) {
        this.defaultPaxSecurityInfo = defaultPax;
        this.securityInfoDispatchers.addDestinationInfo(defaultPax.addressInfo[0]);
        this.securityInfoDispatchers.addEmergencyContactInfo(defaultPax.contactInfo);
        this.isTravelInformationFormValid = this.isEmergencyContactInfoFormValid
          = this.isSameTravelInfo = this.isSameEmergencyContact = true;
      }
    });
    this.countryOfResidence = new UntypedFormControl('', [Validators.required, CustomValidators.noMatch(this.countriesList)]);

    // set rendering.props with paxName & defaultPaxName (if any)
    this.passengerInfo$.pipe(take(1)).subscribe(paxInfo => {
      if (!!paxInfo) {
        this.currentPassengerId = paxInfo;
        const paxName = this.titleCasePipe.transform(`${paxInfo.firstName} ${paxInfo.lastName}`);
        this.rendering = this.dynamicContentService.fillContentProps(this.rendering, {
          paxName: paxName,
          defaultPaxName: this.defaultPaxSecurityInfo && this.titleCasePipe.transform(`${this.defaultPaxSecurityInfo.passengerInfo.firstName} ${this.defaultPaxSecurityInfo.passengerInfo.lastName}`)
        });
      }
    });

    this.subscriptions.add(this.securityInfo$.subscribe(securityInfoArray => {
      if (!!securityInfoArray) {
        securityInfoArray.filter(securityInfoObject => {
        if (securityInfoObject.passengerInfo.id === this.currentPassengerId.id) {
          this.getValidationStatusOfCurrentPax(securityInfoObject);
        }
      });
      }
    }));
    this.analyticsDispatchers.internationalTravelerInformationPageLoaded();
  }

  scanPassport(): void {
    this.modalsService.openScanPassport('');
  }

  getValidationStatusOfCurrentPax(securityInfoObject: SecurityInfo) {
    const validations = securityInfoObject.validations;
    if (!!validations) {
      validations.filter(validation => {
        if (validation.validationType === 'Timatic') {
          this.canShowForm = false;
        } else {
          this.canShowForm = true;
        }
      });
    } else {
      this.canShowForm = true;
    }
}
  toggleCheckbox(checkbox: MatCheckboxChange): void {
    if (checkbox.source.name === 'travel-info') {
      this.isSameTravelInfo = this.isTravelInformationFormValid = checkbox.checked;
      this.isSameTravelInfo
        ? this.securityInfoDispatchers.addDestinationInfo(this.defaultPaxSecurityInfo.addressInfo[0])
        : this.securityInfoDispatchers.addDestinationInfo(null);
    } else if (checkbox.source.name === 'emergency-contact') {
      this.isSameEmergencyContact = this.isEmergencyContactInfoFormValid = checkbox.checked;
      this.isSameEmergencyContact
        ? this.securityInfoDispatchers.addEmergencyContactInfo(this.defaultPaxSecurityInfo.contactInfo)
        : this.securityInfoDispatchers.addEmergencyContactInfo(null);
    }
  }


  submitCountryOfResidence(countryName: string): void {
    const countryCode = this.countries.countryMap[countryName];
    const residentCountry = {
      addressType: AddressType.RESIDENT,
      streetAddress: '',
      city: '',
      stateProvince: '',
      zipCode: '',
      countryCode: countryCode
    };
    this.securityInfoDispatchers.addResidentInfo(residentCountry);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
