import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { EntryDetail } from '~app/models/trip-model';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { SessionDispatchers, SessionSelectors, TripSelectors, TripDispatchers } from '~app/store';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';
import { PassengerList } from '~app/models/passengerlist.model';
import { Passenger } from '~app/models/passenger.model';
import { ModalsService } from '~app/modals/modals.service';
import { RegexPatterns } from '~app/constants/regex-constants';
import { Trip } from '~app/models/trip.model';
import { Response } from '~app/models/response.model';
import { DropDownOption } from '@hawaiianair/common-models';
import { inputFieldContent } from '@hawaiianair/input-field/lib/ha-input-field.content';
import { DropDownContent } from '@hawaiianair/drop-down';
import { FrequentFlyerValidators } from '~app/validators/frequent-flyer.validator';
import { PassengerDetail } from '~app/models/passengerdetail.model';
import { FeatureFlagClientService } from '~app/services/feature-flag-client.service';
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { ValidationStates } from '@hawaiianair/validation';

@Component({
  selector: 'app-passenger-card',
  templateUrl: './passenger-card.component.html',
  styleUrls: ['./passenger-card.component.scss']
})
@ContentSchema({
  description: 'PassengerCard',
  name: 'PassengerCard',
  props: [
    { name: 'frequentFlyerNumber', type: PropertyType.Text },
    { name: 'frequentFlyerProgram', type: PropertyType.Text },
    { name: 'frequentFlyerOptions', type: PropertyType.Object },
    { name: 'redressNumber', type: PropertyType.Text },
    { name: 'redressNumberLabel', type: PropertyType.Text },
    { name: 'knownTravelerNumber', type: PropertyType.Text },
    { name: 'knownTravelerTitle', type: PropertyType.Text },
    { name: 'country', type: PropertyType.Text },
    { name: 'knownTravelerLabel', type: PropertyType.Text },
    { name: 'alreadyCheckedIn', type: PropertyType.Text },
    { name: 'military', type: PropertyType.Text },
    { name: 'PLAT', type: PropertyType.Text },
    { name: 'PLTK', type: PropertyType.Text },
    { name: 'GOLD', type: PropertyType.Text },
    { name: 'CLUB', type: PropertyType.Text },
    { name: 'frequentFlyerPrograms', type: PropertyType.Object },
    { name: 'frequentFlyerPatternErrorMessage', type: PropertyType.Text },
  ]
})
export class PassengerCardComponent implements OnInit, OnDestroy {
  @Input() detail: EntryDetail;
  @Input() rendering: ComponentRendering;
  @Input() listIndex: Number;
  @Output() disableDefaultNavButton = new EventEmitter<boolean>();

  tripPassengersList$: Observable<PassengerList>;
  sessionPassengerIds$: Observable<string[]>;
  isSinglePax$: Observable<boolean>;
  tripId$: Observable<string>;
  tripData$: Observable<Response<Trip>>;
  sessionSelectedSegmentId$: Observable<string>;
  tripError$: Observable<boolean | {}>;
  tripData: Response<Trip>;
  isPassTravel = false;

  subs = new Subscription();
  isCheckedIn: boolean;
  isSinglePax: boolean;
  passenger: Passenger;
  passengerCompanyText: string;
  tripId: string;
  singlePaxLoyaltyFilled: boolean;
  isPaxPreselected: boolean;
  patchInputType: string;
  segmentId: string;
  isKtnInvalid: boolean;
  isRedressInvalid: boolean;

  frequentFlyerNumbersForm: UntypedFormGroup;
  countriesList: DropDownOption[] = [{ value: null, text: 'N/A' }, { value: 'USA', text: 'USA' }, { value: 'CAN', text: 'CAN' }];
  frequentFlyerNumberInputContent: inputFieldContent;
  frequentFlyerProgramContent: DropDownContent;
  redressCountryDropdownContent: DropDownContent;
  redressInputContent: inputFieldContent;
  ktnCountryDropdownContent: DropDownContent;
  ktnInputContent: inputFieldContent;
  frequentFlyerOptions: DropDownOption[];
  memberNumberValidationStates: ValidationStates;

  constructor(
    private featureFlagClient: FeatureFlagClientService,
    private sessionDispatchers: SessionDispatchers,
    private sessionSelectors: SessionSelectors,
    private tripSelectors: TripSelectors,
    private tripDispatchers: TripDispatchers,
    private modalsService: ModalsService,
    private dynamicContentService: DynamicContentService,
  ) {
    this.tripPassengersList$ = this.tripSelectors.tripPassengersList$;
    this.tripData$ = this.tripSelectors.trip$;
    this.sessionPassengerIds$ = this.sessionSelectors.sessionPassengerIds$;
    this.isSinglePax$ = this.tripSelectors.isSinglePax$;
    this.tripId$ = this.tripSelectors.tripId$;
    this.tripError$ = this.tripSelectors.tripError$;
    this.sessionSelectedSegmentId$ = this.sessionSelectors.sessionSelectedSegmentId$
  }

  ngOnInit() {
    this.setFormContent();
    const isAddingAlaskaFFNumberEnabled = this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.ENABLE_ADDING_ALASKA_FF_NUMBER);
    const isAddingOAFFNumberEnabled = this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.ENABLE_ADDING_OA_FF_NUMBER);
    this.frequentFlyerOptions = isAddingAlaskaFFNumberEnabled && isAddingOAFFNumberEnabled ? 
      this.rendering?.props?.frequentFlyerOptions : 
        this.rendering?.props?.frequentFlyerOptions.filter(program => isAddingAlaskaFFNumberEnabled ? ['AS', 'HA', null].includes(program?.value) : 
          isAddingOAFFNumberEnabled? program?.value !== 'AS' : ['HA', null].includes(program?.value));
    this.sessionPassengerIds$.pipe(take(1)).subscribe(id => this.isPaxPreselected = id.includes(this.detail.passengerId));
    this.sessionSelectedSegmentId$.pipe(take(1)).subscribe(id => this.segmentId = id);
    this.isSinglePax$.pipe(take(1)).subscribe(bool => this.isSinglePax = bool);
    this.tripId$.pipe(take(1)).subscribe(id => this.tripId = id);
    this.isCheckedIn = this.detail.flightDetails.every(flight => flight.isCheckedIn);
    this.subs.add(this.tripPassengersList$.subscribe(list => {
      const pax = list?.entries.find(entry => this.detail.passengerId === entry.id);
      if (!!pax) {
        this.passenger = pax;
        this.passengerCompanyText = this.rendering?.props?.frequentFlyerPrograms?.[this.passenger?.loyalty?.company] ?? '';
        this.singlePaxLoyaltyFilled = this.isSinglePax && !!list.entries[0]?.loyalty?.number;
      }
    }));
    this.setTripData();

    this.frequentFlyerNumbersForm = new UntypedFormGroup({
      loyalty: new UntypedFormGroup({
        company: new UntypedFormControl(this.rendering?.props?.frequentFlyerOptions?.find(program => program?.value === this.passenger?.loyalty?.company)?.value),
        number: new UntypedFormControl(this.passenger?.loyalty?.number),
      }, FrequentFlyerValidators.createFrequentFlyerValidator()),
      knownTravelerNumber: new UntypedFormGroup({
        issuingCountry: new UntypedFormControl(this.passenger.knownTravelerNumber?.issuingCountry),
        ktn: new UntypedFormControl(this.passenger.knownTravelerNumber?.ktn, [Validators.pattern(RegexPatterns.ktnValidation1A)])
      }, FrequentFlyerValidators.createKTNValidator(true)),
      redressNumber: new UntypedFormGroup({
        issuingCountry: new UntypedFormControl(this.passenger.redressNumber?.issuingCountry),
        redress: new UntypedFormControl(this.passenger.redressNumber?.redress, [Validators.pattern(RegexPatterns.redressValidation)])
      }, FrequentFlyerValidators.createRedressValidator(true))
    }, { updateOn: 'blur' });

    this.frequentFlyerNumbersForm.get('knownTravelerNumber').statusChanges.subscribe(status => {
      this.isKtnInvalid = status === 'INVALID';
      this.disableDefaultNavButton.emit(this.isKtnInvalid || this.isRedressInvalid);
    });
    this.frequentFlyerNumbersForm.get('redressNumber').statusChanges.subscribe(status => {
      this.isRedressInvalid = status === 'INVALID';
      this.disableDefaultNavButton.emit(this.isKtnInvalid || this.isRedressInvalid);
    });

    // updatePassenger on valid & different frequent flyer number input
    Object.keys(this.frequentFlyerNumbersForm.controls).forEach(key => {
      this.subs.add(this.frequentFlyerNumbersForm.controls[key].statusChanges.subscribe(change => {
        if (change === 'VALID' && this.checkFormValueValidity(this.frequentFlyerNumbersForm.controls[key], key)) {
          const paxUpdate: PassengerDetail[] = [{
            passengerId: this.detail.passengerId,
            segmentId: this.segmentId,
            [key]: this.frequentFlyerNumbersForm.controls[key].value
          }];
          this.patchInputType = key;
          this.tripDispatchers.updatePassenger(this.tripId, paxUpdate, key, '');
        }
      }));
    });

    this.frequentFlyerNumbersForm.get(['loyalty', 'company'])?.valueChanges.subscribe(selectedProgramValue => {
      const selectedProgram = this.rendering?.props?.frequentFlyerOptions
        ?.find(program => program.value === selectedProgramValue);

      this.memberNumberValidationStates = {
        pattern: this.dynamicContentService.getContentString(
          this.rendering?.props?.frequentFlyerPatternErrorMessage,
          { programName: selectedProgram?.text }
        ),
      };

      if (!selectedProgramValue) {
        this.frequentFlyerNumbersForm.get(['loyalty', 'number'])?.setValue('');
      }
    });

    // reset back to initial value if updatePassenger error occurred
    this.subs.add(this.tripError$.subscribe(error => {
      if (error && this.patchInputType) {
        this.frequentFlyerNumbersForm.controls[this.patchInputType].reset(this.passenger[this.patchInputType]);
      }
    }));
  }

  togglePassengerCheckbox(event: MouseEvent): void {
    if (!this.isSinglePax) {
      event.stopPropagation();
      this.updatePassengerIdsInSession();
    }
  }

  updatePassengerIdsInSession(): void {
    this.isPaxPreselected = !this.isPaxPreselected;
    let sessionDataPassengerIds;
    this.subs.add(this.sessionPassengerIds$.pipe(take(1)).subscribe(passengerIds => sessionDataPassengerIds = passengerIds));
    if (this.isPaxPreselected && (!sessionDataPassengerIds.length || !sessionDataPassengerIds.includes(this.detail.passengerId))) {
      this.sessionDispatchers.addSessionPassengerIds('passengerIds', this.detail.passengerId);
    } else {
      this.sessionDispatchers.setSessionPassengerIds(
        'passengerIds', sessionDataPassengerIds.filter(item => item !== this.detail.passengerId)
      );
    }
  }

  // checks form value for validity to updatePassenger
  private checkFormValueValidity(control: AbstractControl, patchKey: string): boolean {
    if (!control || !control?.valid || !control?.value || Object.values(control.value).includes(null)) {
      return false;
    }

    if (!this.passenger?.[patchKey]) {
      return true;
    }

    return Object.values(control.value).some(value => !Object.values(this.passenger[patchKey]).includes(value));
  }

  openKnowTravelerAndRedressModal(): void {
    this.modalsService.openFAQ(false);
  }

  setTripData(): void {
    this.subs.add(this.tripData$.subscribe(trip => {
      if (trip) {
        this.tripData = trip;
        this.isPassTravel = trip?.results?.[0]?.isPassTravel;
      }
    }));
  }

  clickRemoveInfantLink(): void {
    this.modalsService.openRemoveInfantFromPaxCardModal({
      tripId: this.tripData.results[0].id, confirmationCode: this.tripData.results[0].confirmationCode, passengerId: this.detail.passengerId
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  private setFormContent(): void {
    this.frequentFlyerNumberInputContent = {
      inputId: `frequent-flyer-number-input-${this.listIndex}`,
      label: this.rendering?.props['frequentFlyerNumber'],
      placeHolderText: this.rendering?.props['frequentFlyerNumber']
    };
    this.frequentFlyerProgramContent = {
      inputId: 'frequent-flyer-program-content-{{listIndex}}',
      label: this?.rendering?.props['frequentFlyerProgram'],
    };
    this.redressCountryDropdownContent = {
      inputId: 'redress-number-country-dropdown-{{listIndex}}',
      label: this.rendering?.props['country'],
      placeholderText: this.rendering?.props['country']
    };
    this.redressInputContent = {
      inputId: 'redress-number-input-{{listIndex}}',
      label: this.rendering?.props['redressNumberLabel'],
      placeHolderText: this.rendering.props['redressNumber']
    };
    this.ktnCountryDropdownContent = {
      inputId: 'known-traveler-number-country-dropdown-{{listIndex}}',
      label: this.rendering?.props['country'],
      placeholderText: this.rendering?.props['country']
    };
    this.ktnInputContent = {
      inputId: 'known-traveler-number-input-{{listIndex}}',
      label: this.rendering?.props['knownTravelerLabel'],
      placeHolderText: this.rendering.props['knownTravelerNumber']
    };
  }
}
