import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { ContactTracingDetails, ContactTracingPassenger } from '~app/models/contactTracingDetails.model';
import { EmergencyContactDetail } from '~app/models/emergencyContact.model';
import { InternationalDetailsDispatchers, ReferenceDataSelectors } from '~app/store';
import { Countries } from '~app/store/reducers/reference-data/reference-data.reducer';
import { CustomValidators } from '~app/validators/noMatch.validator';
import { RegexPatterns } from '~app/constants/regex-constants';
import { EmergencyContactValidator } from '~app/validators/emergency-contact.validator';
import { HaModalComponent } from '@hawaiianair/modal';
import { PhoneNumberValidators } from '~app/validators/phone-number-validators';
import { EnglishOnlyValidator } from '~app/validators/english-name-validators';

@Component({
  selector: 'app-contact-tracing-passenger-card',
  templateUrl: './contact-tracing-passenger-card.component.html',
  styleUrls: ['./contact-tracing-passenger-card.component.scss'],
})

@ContentSchema({
  name: 'ContactTracingPassengerCard',
  description: 'ContactTracingPassengerCardComponent',
  props: [
    { name: 'enterContactDetails', type: PropertyType.Text },
    { name: 'email', type: PropertyType.Text },
    { name: 'emailAddress', type: PropertyType.Text },
    { name: 'phoneNumber', type: PropertyType.Text },
    { name: 'countryCode', type: PropertyType.Text },
    { name: 'secondaryEmergency', type: PropertyType.Text },
    { name: 'fullName', type: PropertyType.Text },
    { name: 'address', type: PropertyType.Text },
    { name: 'addressOption1', type: PropertyType.Text },
    { name: 'addressOption2', type: PropertyType.Text },
    { name: 'city', type: PropertyType.Text },
    { name: 'state', type: PropertyType.Text },
    { name: 'zipCode', type: PropertyType.Text },
    { name: 'confirmationHeader', type: PropertyType.Text },
    { name: 'confirmationOption1', type: PropertyType.Text },
    { name: 'confirmationOption2', type: PropertyType.Text },
    { name: 'useInformationForAllGuests', type: PropertyType.Text },
    { name: 'sameInformationForAllGuestsAcknowledgement', type: PropertyType.Text },
    { name: 'states', type: PropertyType.Object },
  ]
})
export class ContactTracingPassengerCardComponent implements OnInit {
  @Input() contactTracingPassenger: ContactTracingPassenger;
  @Input() rendering: ComponentRendering;
  @Input() showSaveAllPaxOption: boolean;
  @Output() saveAllPax = new EventEmitter();
  @ViewChild('contactTracingModal') contactTracingModal: HaModalComponent;

  countries: Countries;
  countryList: {
    countryPhonePrefix: string;
    country2LetterCode: string;
    displayName: string;
  }[];
  isATTS: boolean;
  isSameContactInformationSelected = false;
  isAcknowledge = false;
  isCompletedConfirmed = false;
  contactTracingForm: UntypedFormGroup;
  passengerFullName: string;

  constructor(
    private internationalDetailsDispatchers: InternationalDetailsDispatchers,
    private referenceDataSelectors: ReferenceDataSelectors,
  ) { }

  ngOnInit(): void {
    this.passengerFullName = this.contactTracingPassenger?.passenger.firstName + ' ' + this.contactTracingPassenger.passenger.lastName;
    this.referenceDataSelectors.countries$.subscribe(countries => {
      this.countries = countries;
      this.countryList = countries?.countryDisplayName?.map(countryName => {
        const country = this.countries?.countryList?.find(countryData => countryData?.countryNm?.toLowerCase() === countryName?.toLowerCase());
        return {
          countryPhonePrefix: country?.countryPhonePrefix,
          country2LetterCode: country?.country2LetterCode,
          displayName: countryName
        }
      })
    });

    this.isATTS = this.contactTracingPassenger?.passenger?.specialKeywords?.includes('ATTS');
  }

  buildContactTracingForm(): void {
    // if not yet submitted create a new form, otherwise use existing
    if (!this.contactTracingPassenger?.isCompleted) {
      const paxInfo = this.contactTracingPassenger?.passengerInfo;
      this.contactTracingForm = new UntypedFormGroup({
        inTransit: new UntypedFormControl(false, [Validators.required]),
        primaryPhoneNumber: new UntypedFormGroup({
          number: new UntypedFormControl('', [Validators.required, PhoneNumberValidators.pattern()]),
          countryCode: new UntypedFormControl('', [Validators.required, CustomValidators.noMatch(Object.values(this.countries?.countryMap))]),
          countryPhoneExtension: new UntypedFormControl('')
        }),
        email: new UntypedFormControl(paxInfo?.contacts?.find(contact => contact?.contactType?.toUpperCase() === 'EMAIL')?.address?.toLowerCase() ?? '', [Validators.required, Validators.email]),
        emergencyContact: new UntypedFormGroup({
          name: new UntypedFormControl(paxInfo?.emergencyContacts?.[0]?.phone?.addresseeName ?? '', [EnglishOnlyValidator.pattern()]),
          phoneNumber: new UntypedFormGroup({
            number: new UntypedFormControl(paxInfo?.emergencyContacts?.[0]?.phone?.number ?? '', [Validators.required, PhoneNumberValidators.pattern()]),
            countryCode: new UntypedFormControl(this.countries?.country3CodeMap?.[paxInfo?.emergencyContacts?.[0]?.phone?.countryCode] ?? ''),
            countryPhoneExtension: new UntypedFormControl(this.countries?.countryList?.find(
              country => country?.country3LetterCode === paxInfo?.emergencyContacts?.[0]?.phone?.countryCode)?.countryPhonePrefix ?? '')
          }),
        }, { validators: EmergencyContactValidator.createEmergencyContactValidator() })
      });
    }
    else {
      this.contactTracingForm = this.contactTracingPassenger?.contactTracingForm;
    }

    this.buildDestinationAddressForm();

    this.contactTracingForm.get('primaryPhoneNumber.countryCode')?.valueChanges.subscribe(change => {
      this.contactTracingForm.get('primaryPhoneNumber.countryPhoneExtension')?.patchValue(
        this.countries.countryList?.find(country => country?.country2LetterCode === change)?.countryPhonePrefix
      );
    });

    this.contactTracingForm.get('emergencyContact.phoneNumber.countryCode')?.valueChanges.subscribe(change => {
      this.contactTracingForm.get('emergencyContact.phoneNumber.countryPhoneExtension')?.patchValue(
        this.countries.countryList?.find(country => country?.country2LetterCode === change)?.countryPhonePrefix
      );
    });

    this.contactTracingForm.get('inTransit')?.valueChanges.subscribe(_ => {
      this.buildDestinationAddressForm();
    });

    this.contactTracingModal?.openDialog();
  }

  cancelContactTracingModal(): void {
    this.contactTracingModal?.closeDialog();
    this.isAcknowledge = false;
    this.isCompletedConfirmed = false;
    this.isSameContactInformationSelected = false;
  }

  private buildDestinationAddressForm(): void {
    if (!this.contactTracingForm?.get('inTransit').value) {
      const destinationAddressForm = new UntypedFormGroup({
        addressLine1: new UntypedFormControl('', [Validators.required, CustomValidators.characterCountError(RegexPatterns.addressValidation)]),
        countryCode: new UntypedFormControl('US', [Validators.required, CustomValidators.noMatch(Object.values(this.countries?.countryMap))]),
        cityName: new UntypedFormControl('', [Validators.required, CustomValidators.characterCountError(RegexPatterns.cityValidation)]),
        stateCode: new UntypedFormControl('', [Validators.required, CustomValidators.characterCountError(RegexPatterns.stateCodeValidation)]),
        zipCode: new UntypedFormControl('', [Validators.required, CustomValidators.characterCountError(RegexPatterns.zipCodeValidation)])
      });
      this.contactTracingForm?.addControl('destinationAddress', destinationAddressForm);
    }
    else {
      this.contactTracingForm?.removeControl('destinationAddress');
    }
  }

  private checkObjectTruthy(obj: EmergencyContactDetail): boolean {
    return Object.values(obj).every(item => typeof item === 'object' ? this.checkObjectTruthy(item) : !!item);
  }

  makeContactDetailsForSubmit(contactTracingPassenger: ContactTracingPassenger): ContactTracingDetails {
    const isDeclined = !this.checkObjectTruthy(contactTracingPassenger.contactTracingForm?.value?.emergencyContact);
    const contactTracingDetails: ContactTracingDetails = {
      ...contactTracingPassenger?.contactTracingForm?.value,
      emergencyContact: {
        declined: isDeclined,
        ...(!isDeclined && contactTracingPassenger.contactTracingForm?.value.emergencyContact)
      },
      passenger: {
        passengerId: contactTracingPassenger.passenger.passengerId,
        lastName: contactTracingPassenger.passenger.lastName
      },
      passengerSegments: contactTracingPassenger.passengerSegments
    };
    return contactTracingDetails;
  }

  onContactTracingSubmit(contactTracingPassenger: ContactTracingPassenger): void {
    this.contactTracingPassenger.contactTracingForm = this.contactTracingForm;
    if (this.isSameContactInformationSelected) {
      this.saveAllPax.emit();
    } else {
      this.internationalDetailsDispatchers.postContactTracingInformation(this.makeContactDetailsForSubmit(contactTracingPassenger));
    }

    this.contactTracingModal?.closeDialog();
  }
}

