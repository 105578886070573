import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { Observable, Subscription, take } from 'rxjs';
import { CountryMap, CountryName } from '~app/models/country.model';
import { PassengerName } from '~app/models/passengername.model';
import { InternationalDetailsDispatchers, ReferenceDataSelectors } from '~app/store';
import { InternationalDetailsSelectors } from '~app/store/services/international-details/international-details.selectors';
import { CustomValidators } from '~app/validators/noMatch.validator';
import { PURPOSE_OF_VISIT } from '~app/constants/ha-constants';

@Component({
  selector: 'app-residence-information',
  templateUrl: './residence-information.component.html',
  styleUrls: ['./residence-information.component.scss']
})
@ContentSchema({
  name: 'ResidenceInformation',
  description: 'ResidenceInformationComponent',
  props: [
    { name: 'heading', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'countryOfResidenceLabel', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
  ]
})
export class ResidenceInformationComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  subscription = new Subscription();
  loading$: Observable<boolean>;
  countriesList: CountryName[];
  countryMap: CountryMap;
  isContinueButtonEnabled = false;
  passengers: {
    id: string;
    passengerName: PassengerName;
    countryOfResidence: UntypedFormControl
  }[];

  constructor(
    private referenceDataSelectors: ReferenceDataSelectors,
    private internationalDetailsSelectors: InternationalDetailsSelectors,
    private internationalDetailsDispatchers: InternationalDetailsDispatchers,
  ) {
    this.loading$ = this.internationalDetailsSelectors.internationalDetailsLoading$;
  }

  ngOnInit(): void {
    this.referenceDataSelectors.countries$.pipe(take(1)).subscribe((countries) => {
      this.countriesList = countries.countryDisplayName;
      this.countryMap = countries.countryMap;
    });

    this.subscription.add(this.internationalDetailsSelectors.internationalPassengers$.subscribe(paxes => {
      this.passengers = paxes?.map((pax) => {
        return {
          ...pax,
          countryOfResidence: new UntypedFormControl('', [Validators.required, CustomValidators.noMatch(this.countriesList)])
        }
      });
    }));

    this.passengers.forEach(pax => {
      this.subscription.add(pax.countryOfResidence.statusChanges.subscribe(() => {
        this.isContinueButtonEnabled = this.passengers.every(pax => pax.countryOfResidence.valid);
      }))
    });
  }

  sendResidenceInformation() {
    const personalRegulatoryDetails = this.passengers.map(pax => {
      return {
        passengerId: pax.id,
        personalDetails: {
          countryOfResidence: this.countryMap[pax.countryOfResidence.value],
          purposeOfVisit: PURPOSE_OF_VISIT.TOURIST
        }
      }
    });
    this.internationalDetailsDispatchers.addPersonalRegulatoryDetails(personalRegulatoryDetails);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}