import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Observable, Subscription } from 'rxjs';
import { Flight } from '~app/models/flight.model';
import {
  AircraftImageSelectors,
  AnalyticsDispatchers,
  CartDispatchers,
  CartSelectors,
  CATALOG_ID_SEAT_AMADEUS,
  RouterSelectors,
  SessionDispatchers,
  SessionSelectors,
} from '~app/store';
import { AircraftImageState } from '~app/store/reducers/aircraft-image/aircraft-image.reducers';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SeatmapService } from '~app/components/seat/seatmap.service';
import { Passenger } from '~app/models/passenger.model';
import { SeatAssignment } from '~app/models/seatassignment';
import { MatDialog } from '@angular/material/dialog';
import { ExitRowModalComponent } from '../exit-row-modal/exit-row-modal.component';
import { ICheckinRouteSerializerState } from '~app/store/reducers/router/checkin-route-serializer';
import {
  SeatChangeConfirmationComponent,
  SeatConfirmDlgData,
} from '../seat-change-confirmation/seat-change-confirmation';
import { CartPassengerInfo } from '~app/models/cartpassengerinfo.model';
import { SessionData } from '~app/models/sessiondata.model';
import { CartItem } from '~app/models/cartitem.model';
import { HelperService } from '~app/services/helper.service';
import { AirportMap } from '~app/models/airport.model';
import { ReferenceDataSelectors } from '~app/store/services/reference-data/reference-data.selectors';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { BasicSeatMapComponent, FlightPassenger, SeatInfo } from '@hawaiianair/basic-seat-map';
import { ofType } from '@ngrx/effects';
import * as CartActions from '~app/store/actions/cart.actions';
import { ActionsSubject } from '@ngrx/store';
import { SeatClassType } from '~app/constants/ha-constants';
import { AEPEventNames } from '~app/services/checkin-tagging-data.service';
import { TaggingService } from '@hawaiianair/tagging';
import { AEPTakenSeatPercentage } from '~app/models/adobe-tagging.model';

@Component({
  selector: 'app-seat-map',
  templateUrl: './seat-map.component.html',
  styleUrls: ['./seat-map.component.scss'],
})
@ContentSchema({
  name: 'SeatMap',
  description: 'SeatMap Component',
  props: [
    { name: 'description', type: PropertyType.Text },
    { name: 'operatedByDescription', type: PropertyType.Text },
    { name: 'seatMapErrorDescription', type: PropertyType.Text },
    { name: 'loadingSeatmap', type: PropertyType.Text },
    { name: 'navButtons', type: PropertyType.Object },
  ],
})
export class SeatMapComponent implements OnInit, OnDestroy {
  @Input() rendering: ComponentRendering;
  @ViewChild(BasicSeatMapComponent) basicSeatMap: BasicSeatMapComponent;
  seatConfirmRendering: ComponentRendering;
  upgradeModalRendering: ComponentRendering;
  exitRowModalRendering: ComponentRendering;
  seatUnavailableRendering: ComponentRendering;
  seatmapLegendRendering: ComponentRendering;

  segmentFlight: Flight;
  aircraftImageState$: Observable<AircraftImageState>;
  selectedPassengers$: Observable<Passenger[]>;
  originallyAssignedSeats$: Observable<SeatAssignment[]>;
  assignedSeats$: Observable<SeatAssignment[]>;
  routerState$: Observable<ICheckinRouteSerializerState>;
  segmentFlights$: Observable<Flight[]>;
  sessionState$: Observable<SessionData>;
  cartLoading$: Observable<boolean>;
  browserDevice$: Observable<string>;
  airports$: Observable<AirportMap>;

  sessionData: SessionData;
  flightSeatMapSubs: Subscription = new Subscription();
  flight: Flight;
  selectedPassengers: Passenger[];
  svg: SafeHtml;
  seatAssignments: SeatAssignment[] = [];
  originalSeatAssignments: SeatAssignment[] = [];
  selectedPax: { id: string, name: string };
  isHAFlight = true;
  browser: string;
  seatMapUnavailable = false;
  flightPassengers: FlightPassenger[] = [];
  flightIndex: number = 0;
  paxIdx: number = 0;

  constructor(
    private aircraftImageSelectors: AircraftImageSelectors,
    private sanitizer: DomSanitizer,
    private seatmapSvc: SeatmapService,
    private sessionSelectors: SessionSelectors,
    public dialog: MatDialog,
    private routerSelectors: RouterSelectors,
    private sessionDispatchers: SessionDispatchers,
    private cartDispatchers: CartDispatchers,
    private cartSelectors: CartSelectors,
    private helperService: HelperService,
    private airportSelectors: ReferenceDataSelectors,
    private dynamicContentService: DynamicContentService,
    private actionsSubj: ActionsSubject,
    private analyticsDispatchers: AnalyticsDispatchers,
    private taggingService: TaggingService,
  ) {
    this.aircraftImageState$ = this.aircraftImageSelectors.aircraftImageState$;
    this.selectedPassengers$ = this.sessionSelectors.selectedPassengers$;
    this.originallyAssignedSeats$ = this.sessionSelectors.originallyAssignedSeats$;
    this.assignedSeats$ = this.sessionSelectors.recentlySavedSeats$;
    this.routerState$ = this.routerSelectors.routerState$;
    this.segmentFlights$ = this.sessionSelectors.sessionSelectedSegmentFlights$;
    this.sessionState$ = this.sessionSelectors.session$;
    this.cartLoading$ = this.cartSelectors.cartLoading$;
    this.browserDevice$ = this.sessionSelectors.browserDevice$;
    this.airports$ = this.airportSelectors.airports$;
    this.actionsSubj.pipe(
      ofType(CartActions.cartErrorAccepted),
    ).subscribe(data => {
      this.basicSeatMap.undoSeatSelection();
    })
  }

  ngOnInit() {
    this.seatConfirmRendering = (this.rendering.components['seat-change-confirmation-dialog'] || [{}])[0];
    this.upgradeModalRendering = (this.rendering.components['upgrade-all-pax-modal'] || [{}])[0];
    this.exitRowModalRendering = (this.rendering.components['exit-row-modal'] || [{}])[0];
    this.seatUnavailableRendering = (this.rendering.components['seat-unavailable-modal'] || [{}])[0];
    this.seatmapLegendRendering = (this.rendering.components['seatmap-legend'] || [{}])[0];

    this.cartDispatchers.storeCartItems();
    this.flightSeatMapSubs.add(this.browserDevice$.subscribe(browser => this.browser = browser));
    this.flightSeatMapSubs.add(this.routerState$.subscribe(
      state => {
        if (state?.queryParams?.flightId) {
          this.segmentFlights$.subscribe(flights => {
            if (!!flights) {
              this.segmentFlight = flights.find(flight => flight?.id === state.queryParams.flightId);
              this.isHAFlight = this.helperService.isHAFlight(state.queryParams.flightId, flights);
              if (!this.isHAFlight && this.segmentFlight) {
                this.rendering.props['operatedByDescription'] = this.dynamicContentService.getContentString(
                  this.rendering.props['operatedByDescription'],
                  { otherAirline: this.segmentFlight.operatedByDescription },
                );
              }
            }
          });

          this.flightSeatMapSubs.add(this.assignedSeats$.subscribe(seats => {
            if (this.segmentFlight) {
              this.seatAssignments = seats && seats.filter(seat => seat.flightId === this.segmentFlight.id);
            }
          }));

          if (this.segmentFlight && this.isHAFlight) {
            this.initSeatMap();
          }
        }
      },
    ));
    this.analyticsDispatchers.seatmapPageLoaded();
  }

  ngOnDestroy(): void {
    this.flightSeatMapSubs.unsubscribe();
  }

  initSeatMap() {
    this.flightSeatMapSubs.add(this.sessionState$.subscribe(session => {
      (session) ? this.sessionData = session : this.sessionDispatchers.getState();
    }));

    this.flightSeatMapSubs.add(
      this.selectedPassengers$.subscribe(
        passengers => this.selectedPassengers = passengers,
      ),
    );

    this.flightSeatMapSubs.add(this.segmentFlights$.subscribe(segmentFlights => {
      if (segmentFlights.length && !this.flight && !this.svg) {
        this.flight = segmentFlights.find(flight => flight.id === this.segmentFlight.id);
        if (this.flight) {
          this.flightIndex = segmentFlights.indexOf(this.flight)
          this.initSvg(this.flightIndex);
        }
      }
    }));
  }

  initSvg(flightIndex: number) {
    this.aircraftImageState$.subscribe(state => {
      if (state && !state.loading && !this.svg) {
        const aircraftImageObj = state.aircraftImages.find(seatmap => seatmap.flightIndex === flightIndex);
        this.svg = aircraftImageObj ? this.sanitizer.bypassSecurityTrustHtml(aircraftImageObj.aircraftImage) : null;
        if (this.svg) {
          this.taggingService.trackEvent(AEPEventNames.SEATMAP_LOADED, this.formatAEPSeatmapLoadedEvent(this.svg));
          this.initSeats();
          this.seatMapUnavailable = false;
        }
        else {
          this.seatMapUnavailable = true;
        }
      }
    });
  }

  initSeats() {
    this.flightSeatMapSubs.add(this.originallyAssignedSeats$.subscribe(seats => this.originalSeatAssignments = seats));
    this.flightSeatMapSubs.add(this.assignedSeats$.subscribe(seats => {
      if (seats) {
        seats.forEach(seat => {
          if (seat?.flightId === this.flight?.id) {
            this.flightPassengers.push({
              paxName: seat?.paxName?.firstName + ' ' + seat?.paxName?.lastName,
              paxId: seat.paxId,
              selectedSeatId: seat.seatId,
              isEliteMember: null,
            });
          }
        });

        if (!this.selectedPax) {
          this.selectedPax = {
            id: this.seatAssignments[this.paxIdx]?.paxId,
            name: this.seatAssignments[this.paxIdx]?.paxName?.firstName + ' ' + this.seatAssignments[this.paxIdx]?.paxName?.lastName,
          };
        }
      }
    }));
  }

  emitSeat(event: SeatInfo) {
    let seatClass;
    switch (event.clickedClass) {
      case 'ec':
        seatClass = SeatClassType.EXTRA_COMFORT;
        break;
      case 'fc':
        seatClass = SeatClassType.First_Class;
        break;
      case 'ps':
        seatClass = SeatClassType.Preferred_Seat;
        break;
      case 'mc':
        seatClass = SeatClassType.Main_Cabin;
        break;
      default:
        seatClass = SeatClassType.Main_Cabin;
    }

    let newSeatAssign = {
      paxId: this.selectedPax.id,
      flightId: this.segmentFlight.id,
      seatId: event.seatId,
      isExitRow: event.isExitRow,
      seatClass: seatClass,
      cost: parseInt(event.seatPrice) || 0,
      offerId: event.offerId,
      productId: event?.productId || '',
      description: event.description,
      pricedCabin: event.pricedCabin,
    }

    if (newSeatAssign.isExitRow) {
      // exit row case
      const exitRowDlgRef = this.dialog.open(ExitRowModalComponent, {
        data: this.exitRowModalRendering,
      });

      exitRowDlgRef.afterClosed().subscribe(
        (res) => {
          if (!res) {
            // user clicked declined. do nothing
            this.basicSeatMap.undoSeatSelection();
            return;
          }
          this.openSeatConfirmDlg(newSeatAssign);
        },
      );
    }
    else {
      this.openSeatConfirmDlg(newSeatAssign);
    }
  }

  private openSeatConfirmDlg(newSeatAssign: SeatAssignment) {
    const dlgData: SeatConfirmDlgData = {
      rendering: this.seatConfirmRendering,
      oldSeatInfo: this.getSeatId(newSeatAssign.paxId, newSeatAssign.flightId),
      newSeatInfo: newSeatAssign.seatId,
      newSeatClass: newSeatAssign.seatClass,
      costInfo: newSeatAssign.cost,
    };

    const dialogRef = this.dialog.open(SeatChangeConfirmationComponent, {
      data: dlgData,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // check if the pax is returning back to the original seat.
        if (newSeatAssign.seatId !== this.getOriginalSeat(newSeatAssign.paxId, newSeatAssign.flightId)) {
          let segmentId1A = this.helperService.getAmadeusStyleFlightId(this.segmentFlight);
          const associatedPax: CartPassengerInfo = {
            passengerId: newSeatAssign.paxId,
            flightId: newSeatAssign.flightId,
            segmentId: segmentId1A,
            legId: '0',
            journeyId: this.sessionData.selectedSegment.id,
          };
          const newItem: CartItem[] = [{
            productId: newSeatAssign.productId,
            catalogId: CATALOG_ID_SEAT_AMADEUS,
            associatedPassenger: associatedPax,
            price: newSeatAssign.cost,
            seatCategory: newSeatAssign.seatClass,
            quantity: 1,
            seat: newSeatAssign.seatId,
            offerId: newSeatAssign.offerId,
            pricedCabin: newSeatAssign.pricedCabin,
            description: newSeatAssign.description,
          }];
          this.cartDispatchers.getCartWithSeats(
            this.sessionData.cartId,
            newItem,
            newItem?.[0]?.catalogId,
            'seats',
            '[Seatmap Component]',
          );
          this.paxIdx++;
          if (this.paxIdx === this.seatAssignments?.length) {
            this.paxIdx = 0;
          }
          this.selectedPax = {
            id: this.seatAssignments[this.paxIdx]?.paxId,
            name: this.seatAssignments[this.paxIdx]?.paxName?.firstName + ' ' + this.seatAssignments[this.paxIdx]?.paxName?.lastName,
          }
        }
        else {
          // User returning back to the original seat.
          this.cartDispatchers.updateCartByRemovingASeatItem(
            this.sessionData.cartId,
            this.sessionData.selectedSegment.id,
            newSeatAssign.flightId,
            newSeatAssign.paxId,
          );
        }
        this.seatmapSvc.updateSelectedSeats(this.seatAssignments);
      }
      else {
        this.basicSeatMap?.undoSeatSelection();
      }
    });
  }

  getSeatId(paxId: string, flightId: string): string {
    const seat = this.seatAssignments?.find(seatAssignment => seatAssignment.paxId === paxId && seatAssignment.flightId === flightId);
    return !!seat ? seat.seatId : '';
  }

  private getOriginalSeat(paxId: string, legId: string) {
    const seat = this.originalSeatAssignments.find(assign => assign.flightId === legId && assign.paxId === paxId);
    return seat ? seat.seatId : null;
  }

  onPaxChipClick(assignment: SeatAssignment, paxIdx: number) {
    this.paxIdx = paxIdx;
    if (this.isHAFlight) {
      this.selectedPax = {
        id: assignment.paxId,
        name: assignment?.paxName?.firstName + ' ' + assignment?.paxName?.lastName,
      };
      this.seatmapSvc.updatePassengerId(assignment.paxId);
    }
  }

  formatAEPSeatmapLoadedEvent(svg): AEPTakenSeatPercentage {
    const svgString = svg?.toString();
    if (!svgString) { return null; }
    const mcSeats = svgString.match(/data-seat-class="MC"/g);
    const mcSeatsAvailable = svgString.match(/ class="mc"/g) || [];
    const psSeats = svgString.match(/data-seat-class="PS"/g);
    const psSeatsAvailable = svgString.match(/ class="ps"/g) || [];
    const ecSeats = svgString.match(/data-seat-class="EC"/g);
    const ecSeatsAvailable = svgString.match(/ class="ec"/g) || [];
    const fcSeats = svgString.match(/data-seat-class="FC"/g);
    const fcSeatsAvailable = svgString.match(/ class="fc"/g) || [];
    return {
      takenMCSeats: Math.round((1 - mcSeatsAvailable?.length / mcSeats?.length) * 100).toString(),
      takenPSSeats: Math.round((1 - psSeatsAvailable?.length / psSeats?.length) * 100).toString(),
      takenECSeats: Math.round((1 - ecSeatsAvailable?.length / ecSeats?.length) * 100).toString(),
      takenFCSeats: Math.round((1 - fcSeatsAvailable?.length / fcSeats?.length) * 100).toString(),
    };
  }
}
