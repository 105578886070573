// TODO: refactor user segment info into a better structure for strong typing

/**
 * All feature flag user and user context items go in this namespace.
 * You can add to these but *do **not** modify the string values
 * unless you know what you are doing* - these values are what drive 
 * the User Segment and Rules in the LaunchDarkly dashboard.
 */
export namespace FeatureFlagUser {

  /**
   * User object to hold everything we know (or want the feature flag system) to
   * know about.  User segments and rules are driven by these properties.
   */
  export class User {
    // Generic properties (maps to LD out-of-the-box user context)
    public key?: string;
    public secondaryKey?: string;
    public name?: string;
    public firstName?: string;
    public lastName?: string;
    public emailAddress?: string;
    public avatar?: string;
    public ipAddress?: string;
    public country?: string;
    public isAnonymous?: boolean;

    // These are custom HA properties to help with segmentation
    public market?: string;
    public marketType?: string;
    public loyaltyNumber?: string;
    public loyaltyTier?: string;
    public origin?: string;
    public destination?: string;
    public paxCountAdult?: number;
    public paxCountChild?: number;
    public paxCountInfant?: number;
    public currency?: string;
    public isGroupBooking?: boolean;
    public isCorporateBooking?: boolean;
    public tripType?: string;
    public pnr?: string;
    public availableCredit?: number;
    public fullyCancelledTripCount?: number;
    public isNRSA?: boolean;

    constructor(
      key?: string,
      secondaryKey?: string,
      name?: string,
      firstName?: string,
      lastName?: string,
      emailAddress?: string,
      avatar?: string,
      ipAddress?: string,
      country?: string,
      isAnonymous?: boolean,
      market?: string,
      marketType?: string,
      loyaltyNumber?: string,
      loyaltyTier?: string,
      origin?: string,
      destination?: string,
      paxCountAdult?: number,
      paxCountChild?: number,
      paxCountInfant?: number,
      currency?: string,
      isGroupBooking?: boolean,
      isCorporateBooking?: boolean,
      tripType?: string,
      pnr?: string,
      availableCredit?: number,
      fullyCancelledTripCount?: number,
      isNRSA?: boolean,
    ) {
      this.key = key;
      this.secondaryKey = secondaryKey;
      this.name = name;
      this.firstName = firstName;
      this.lastName = lastName;
      this.emailAddress = emailAddress;
      this.avatar = avatar;
      this.ipAddress = ipAddress;
      this.country = country;
      this.isAnonymous = isAnonymous;
      this.market = market;
      this.marketType = marketType;
      this.loyaltyNumber = loyaltyNumber;
      this.loyaltyTier = loyaltyTier;
      this.origin = origin;
      this.destination = destination;
      this.paxCountAdult = paxCountAdult;
      this.paxCountChild = paxCountChild;
      this.paxCountInfant = paxCountInfant;
      this.currency = currency;
      this.isGroupBooking = isGroupBooking;
      this.isCorporateBooking = isCorporateBooking;
      this.tripType = tripType;
      this.pnr = pnr;
      this.availableCredit = availableCredit;
      this.fullyCancelledTripCount = fullyCancelledTripCount;
      this.isNRSA = isNRSA;
    }

    withKey(newKey?: string): this {
      this.key = newKey;
      return this;
    }

    withIsAnonymous(newIsAnonymous?: boolean): this {
      this.isAnonymous = newIsAnonymous;
      return this;
    }

    withOrigin(newOrigin?: string): this {
      this.origin = newOrigin.toUpperCase();
      return this;
    }

    withDestination(newDestination?: string): this {
      this.destination = newDestination.toUpperCase();
      return this;
    }

    withIsNRSA(newIsNRSA?: boolean): this {
      this.isNRSA = newIsNRSA;
      return this;
    }
  }
}
