import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ContentSchema, PropertyType, ComponentRendering } from '@hawaiianair/core';
import { CartPassengerInfo } from '~app/models/cartpassengerinfo.model';
import { CartItem } from '~app/models/cartitem.model';
import { CartDispatchers } from '~app/store/services/cart/cart.dispatchers';
import { SessionData } from '~app/models/sessiondata.model';
import { Subscription, Observable, take } from 'rxjs';
import { SessionSelectors } from '~app/store/services/session/session.selectors';
import { SessionDispatchers } from '~app/store/services/session/session.dispatchers';
import { CATALOG_ID_BAG } from '~app/store/services/cart/cart.service';
import { CartSelectors } from '~app/store/services/cart/cart.selectors';
import { Product } from '~app/models/product.model';
import { OffersSelectors } from '~app/store';
import { PassengerProduct } from '~app/models/passengerproduct.model';
import { DynamicContentService } from '~app/services/dynamic-content.service';
import { Constants } from '~app/constants/ha-constants';
import { FeatureFlagClientService } from '~app/services/feature-flag-client.service';
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';

@Component({
  selector: 'app-bags-quantity',
  templateUrl: './bag-quantity.component.html',
  styleUrls: ['./bag-quantity.component.scss'],
})
@ContentSchema({
  description: '',
  name: 'BagQuantity',
  props: [
    { name: 'title', type: PropertyType.Text },
    { name: 'description', type: PropertyType.Text },
    { name: 'close', type: PropertyType.Text },
  ]
})
export class BagQuantityComponent implements OnInit, OnDestroy {
  // todo: use model for actual response data
  @Input() bagDetail: Product;
  @Input() bagContent: any;
  @Input() associatedPax: CartPassengerInfo;
  @Input() isBagPage = false;
  @Input() tagId: string;
  @Input() paxIdx: number;
  rendering: ComponentRendering;
  count: number;
  content: any;
  currencyCode: string;
  subscriptions: Subscription = new Subscription();
  sessionData: SessionData;
  cartResultItems: CartItem[] = [];
  cartResultItems$: Observable<CartItem[]>;
  sessionState$: Observable<SessionData>;
  passengerProduct: PassengerProduct;
  operatedByDescription: string;
  bagsAlreadyAdded: string;
  isOARegularBagsEnabled: boolean;

  constructor(
    private sessionSelectors: SessionSelectors,
    private sessionDispatchers: SessionDispatchers,
    private cartSelectors: CartSelectors,
    private cartDispatchers: CartDispatchers,
    private offersSelectors: OffersSelectors,
    private dynamicContentService: DynamicContentService,
    private featureFlagClient: FeatureFlagClientService,
    ) {
    this.sessionState$ = this.sessionSelectors.session$;
    this.cartResultItems$ = this.cartSelectors.cartResultItems$;
  }

  ngOnInit() {
    this.isOARegularBagsEnabled = this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.WEB_CHECK_IN_SPA_OTHER_AIRLINE_REGULAR_BAGS_ENABLED);

    // get session data
    this.subscriptions.add(this.sessionState$.subscribe(session => {
      (session) ? this.sessionData = session : this.sessionDispatchers.getState();
    }));

    this.subscriptions.add(this.cartResultItems$.subscribe(items => {
      this.cartResultItems = items;
      const bagItems = items?.filter(item =>
        item.catalogId === CATALOG_ID_BAG
        && item.productId === this.bagDetail.productId
        && item.associatedPassenger.journeyId === this.associatedPax.journeyId
        && item.associatedPassenger.passengerId === this.associatedPax.passengerId
        && item.associatedPassenger.segmentIds?.toString() === this.associatedPax.segmentIds?.toString()
        && item.associatedPassenger.passengerSegmentIds?.toString() === this.associatedPax.passengerSegmentIds?.toString()
      );
      this.count = bagItems?.reduce((acc, item) => acc + item?.quantity, 0);
    }));

    if (!!this.bagContent) {
      this.content = this.bagContent;
    }

    if (this.sessionData?.isOAFlight && !this.isOARegularBagsEnabled) {
      const otherAirline = this.sessionData.trip2Checkin.flights.entries.find(flight => flight.operatedBy !== Constants.operatedByHA);
      this.operatedByDescription = this.dynamicContentService.getContentString(this.bagContent.operatedByDescription, { otherAirline: otherAirline?.operatedByDescription });
    }

    if (this.associatedPax?.alreadyAddedRegularBags > 0) {
      this.bagsAlreadyAdded = this.dynamicContentService.getContentString(this.bagContent.bagsAlreadyAdded, { count: this.associatedPax.alreadyAddedRegularBags });
    }

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  saveBags(change: number): void {
    // update count
    const newCount = this.count + change;
    if (newCount < 0 || newCount > this.bagDetail.maxPieces) {
      return;
    }
    this.cartDispatchers.getCartWithBags(
      this.sessionData.cartId,
      this.getCartItems(newCount),
      CATALOG_ID_BAG,
      'bags',
      '[Bags Form Component]'
    );
  }

  private getCartItems(count: number): CartItem[] {
    let offerId: string;
    this.offersSelectors.bagOffers$.pipe(take(1)).subscribe(offer => offerId = offer?.id);
    return [{
      productId: this.bagDetail.productId,
      catalogId: CATALOG_ID_BAG,
      quantity: count,
      offerId: offerId,
      type: this.bagDetail.type,
      attributes: this.bagDetail.attributes,
      associatedPassenger: {
        journeyId: this.associatedPax.journeyId,
        passengerId: this.associatedPax.passengerId,
        segmentIds: this.associatedPax.segmentIds,
        passengerSegmentIds: this.associatedPax.passengerSegmentIds
      }
    }];
  }
}
