import {Component, Input} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-ha-validation',
  templateUrl: './ha-validation.component.html',
  styleUrls: ['./ha-validation.component.scss']
})

export class HaValidationComponent {
  @Input() control: UntypedFormControl;
}
