import { Component, Input, OnInit } from '@angular/core';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthTokenDispatchers, SessionSelectors } from '~app/store';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { Observable } from 'rxjs';
import { RegexPatterns } from '~app/constants/regex-constants';
import { CustomValidators } from "~app/validators/noMatch.validator";

@Component({
  selector: 'app-find-trip-search',
  templateUrl: './find-trip-search.component.html',
  styleUrls: [ './find-trip-search.component.scss' ],
})
@ContentSchema({
  name: 'FindTripSearch',
  description: 'Find Trip Search Component',
  props: [
    { name: 'findYourTrip', type: PropertyType.Text },
    { name: 'findTripSearch', type: PropertyType.Object },
    { name: 'viewMyTrip', type: PropertyType.Text },
    { name: 'checkInNow', type: PropertyType.Text }
  ],
})
export class FindTripSearchComponent implements OnInit {
  @Input() rendering: ComponentRendering;
  isMobile$: Observable<boolean>;
  findTripSearchForm: UntypedFormGroup;
  selectedSearch: object;
  tripSearchValidations: object;

  constructor(
    private authTokenDispatchers: AuthTokenDispatchers,
    private sessionSelectors: SessionSelectors,
  ) {
    this.isMobile$ = this.sessionSelectors.isMobile$;
  }

  ngOnInit(): void {
    this.tripSearchValidations = {
      'confirmationCode': [Validators.required, CustomValidators.characterCountError(RegexPatterns.confirmationCodeValidationAmadeus)],
      'ticketNumber': [Validators.required, CustomValidators.characterCountError(RegexPatterns.eTicketValidation)],
      'hawaiianMilesNumber': [Validators.required, CustomValidators.characterCountError(RegexPatterns.hawaiianMilesValidation)],
    }
    this.selectedSearch = (this.rendering.props['findTripSearch'] || [{}])[0];
    this.updateFindTripForm(this.selectedSearch);
  }

  updateFindTripForm(search: any): void {
    if (search && search.fields) {
      const group: any = {};
      search.fields.forEach(field => {
        if (!!this.tripSearchValidations[field.id]) {
          group[field.id] = new UntypedFormControl('', this.tripSearchValidations[field.id]);
        } else {
          group[field.id] = new UntypedFormControl('', Validators.required);
        }
      });
      this.findTripSearchForm = new UntypedFormGroup(group);
    }
  }

  onSubmit(): void {
    for (const input in this.findTripSearchForm.value) {
      this.findTripSearchForm.value[input] = this.findTripSearchForm.value[input].toUpperCase();
    }
    this.authTokenDispatchers.getAuthToken(this.findTripSearchForm.value, CHECKIN_ROUTES.ROUTE_CHECKIN.component);
  }
}
