import { Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ComponentRendering, ContentSchema, PropertyType } from '@hawaiianair/core';
import { Country } from '~app/models/country.model';

@Component({
  selector: 'app-contact-info-form',
  templateUrl: './contact-info-form.component.html',
  styleUrls: ['./contact-info-form.component.scss']
})

@ContentSchema({
  name: 'ContactInfoForm',
  props: [
    { name: 'firstName', type: PropertyType.Text },
    { name: 'lastName', type: PropertyType.Text },
    { name: 'countryCode', type: PropertyType.Text },
    { name: 'phoneNumber', type: PropertyType.Text },
    { name: 'number', type: PropertyType.Text },
  ]
})
export class ContactInfoFormComponent implements OnDestroy {
  @Input() rendering: ComponentRendering;
  @Input() contactInfoForm: UntypedFormGroup;
  @Input() countriesList: Country[];

  ngOnDestroy(): void {
    for (const key in this.contactInfoForm.controls) {
      this.contactInfoForm.get(key).clearValidators();
      this.contactInfoForm.get(key).updateValueAndValidity();
    }
  }
}
