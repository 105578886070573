import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { RegexPatterns } from '~app/constants/regex-constants';

export class EnglishOnlyValidator extends Validators {
  static pattern(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: string } | null => {
      if (control?.value) {
        const regEx = new RegExp(RegexPatterns.nameValidation);
        return regEx.test(control?.value) ? null : { characterCountError: 'nameValidation' };
      }
      return null;
    }
  }
}
