// TODO: Refactor this service into the ha-shared library as /feature-flags
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { AnalyticsService } from '@hawaiianair/analytics';
import { LogLevel } from '@hawaiianair/common';
import { LocalStorageService } from '@hawaiianair/core';
import { initialize, LDClient, LDContext } from 'launchdarkly-js-client-sdk';
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';
import { FeatureFlagChange } from '~app/models/feature-flag-change.model';
import { FeatureFlagUser } from '~app/models/feature-flag-user.model';
import { environment } from '~environments/environment';
import { LogService } from './log-service';

/**
 * Service used to interact with feature flags.
 *
 * Example usage:
 *
 *  ```
 * import { FeatureFlagClientService } from 'feature-flag-client.service';
 * import { FeatureFlagConstants as ff} from 'feature-flag-constants';
 * ...
 * constructor( ... private featureFlagClient: FeatureFlagClientService ... )
 * ...
 * // Pass as much context as we know about the user
 * this.featureFlagClient.user = this.featureFlagClient.user.withCurrency("usd").withPaxCountAdult(1);
 * this.featureFlagClient.identifyUser(this.featureFlagClient.user);
 * ...
 * if (this.featureFlagClient.getFeatureFlagVariation(ff.EXCHANGE_SHOPPING_MODERNIZATION_ENABLED))
 * {
 *     alert("feature flag is currently ON");
 * }
 * else
 * {
 *     alert("feature flag is currently OFF");
 * }
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagClientService implements OnDestroy {
  // Subject to publish new flags coming along with SSE 'change'
  // using Subject for multicase purpose as multiple components will be subscribing
  flagsChanged: Subject<FeatureFlagChange> = new Subject<FeatureFlagChange>();
  user: FeatureFlagUser.User;

  private client: LDClient;
  private isClientInitialized: boolean;

  constructor(
    private logService: LogService,
    private localStorageService: LocalStorageService,
    private analyticsService: AnalyticsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isClientInitialized = false;
    this.user = this.initializeUser(new FeatureFlagUser.User());
    this.initializeClient(this.user);
  }

  ngOnDestroy() {
    this.flagsChanged.unsubscribe();
  }

  /**
   * Get the active feature flag variation for the flag name and user context.
   * @param string featureFlagName - The unique key of the feature flag.
   * @returns The flag's value.
   */
  getFeatureFlagVariation(featureFlagKey: string): any {
    if (!this.isClientInitialized) {
      const message = 'FeatureFlagClientService is not initialized.';
      this.logService.log(message, 'FeatureFlagClientService', LogLevel.Error);
      throw new Error(message);
    }

    if (!FeatureFlagConstants.supportedFeatureFlags.hasOwnProperty(featureFlagKey)) {
      const message = `Feature Flag ${featureFlagKey} is not currently supported by this App.`;
      this.logService.log(message, 'FeatureFlagClientService', LogLevel.Error);
      throw new Error(message);
    }

    const result = this.client.variation(
      featureFlagKey,
      FeatureFlagConstants.supportedFeatureFlags[featureFlagKey]
    );

    const ffVariationEvent = {
      event: 'getFeatureFlagVariation',
      type: 'feature-flag',
      key: featureFlagKey,
      default: FeatureFlagConstants.supportedFeatureFlags[featureFlagKey],
      actualResult: result
    };
    this.logService.log(ffVariationEvent.event, 'FeatureFlagClientService', LogLevel.Debug, ffVariationEvent);
    this.analyticsService.logEvent(ffVariationEvent.event, ffVariationEvent);

    return result;
  }

  /**
   * Identify the user's context.  We should be passing in as much as we
   * know about the user and their journey context.  You only need to call
   * this when you know more (or less) about a user so that the flags can
   * be re-evaluated.
   * @param FeatureFlagUser.User user - HA feature flag user object.
   */
  async identifyUser(user: FeatureFlagUser.User) {
    if (!this.isClientInitialized) {
      const message = "FeatureFlagClientService is not initialized.";
      this.logService.log(message, "FeatureFlagClientService", LogLevel.Error);
      throw new Error(message);
    }

    this.user = user;
    this.saveUser(this.user);
    await this.client.identify(this.mapUserToLDContext(this.user));

    const ffIdentifyUserEvent = {
      event: "identifyUser",
      type: "feature-flag",
      user: this.user
    };
    this.logService.log(ffIdentifyUserEvent.event, "FeatureFlagClientService", LogLevel.Debug, ffIdentifyUserEvent);
    this.analyticsService.logEvent(ffIdentifyUserEvent.event, ffIdentifyUserEvent);
  }

  /**
   * Initializes the feature flag client without any user details (usually this is
   * only called from app.component.ts to initialize the service).
   *
   * identifyUser should still be called as you learn more information about the
   * specific user.
   *
   */
  public initialize() {
    this.initializeClient(this.user);
  }

  /**
   * Initializes the feature flag client.  This **must** be called prior to any calls to
   * *getFeatureFlagVariation* or *identifyUser*.
   * @param FeatureFlagUser.User user - HA feature flag user object.
   */
  private initializeClient(user: FeatureFlagUser.User) {
    // If already initialized then return
    if (this.isClientInitialized) {
      return;
    }

    // If the app is not running on browser i.e, window is undefined, just return
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // If client already initialized, return
    if (!!this.client) {
      return;
    }

    this.logService.log('FeatureFlagClientService client is about to init', 'FeatureFlagClientService', LogLevel.Debug);

    // Set up some sensible values for our user in case they were not passed in
    this.user = user
      .withKey(user.key ?? uuidv4()) // Give our user a uuid if their key is null from the caller
      .withIsAnonymous(user.isAnonymous ?? true); // If not supplied, anonymous should be true

    // Save the user to local storage
    this.saveUser(this.user);

    // Initialize the client for use
    this.client = initialize(
      environment.ldClientSideId,
      this.mapUserToLDContext(this.user)
    );

    const extra = { event: 'initialized', user: this.user };
    this.logService.log(`FeatureFlagClientService: initialized with user: ${this.user.key}`, 'FeatureFlagClientService', LogLevel.Debug, extra);
    this.isClientInitialized = true;

    // Handle our client events...

    // Once ready, fetch our master kill switch to see if we are supposed to be on or not
    this.client.on('ready', () => {
      const eventExtra1 = { event: 'ready' };
      this.logService.log('FeatureFlagClientService (Ready event received)', 'FeatureFlagClientService', LogLevel.Debug, eventExtra1);
    });

    // If we receive a Server-Side Events (SSE) then update our flag
    this.client.on('change', (newFlags) => {
      const eventExtra2 = { event: 'change', newFlags: newFlags };
      this.logService.log('FeatureFlagClientService (Change event received)', 'FeatureFlagClientService', LogLevel.Debug, eventExtra2);

      // Iterate through the changes flags, and notify observers for each flag that the App supports
      Object.keys(newFlags).forEach((key) => {
        if (FeatureFlagConstants.supportedFeatureFlags.hasOwnProperty(key)) {
          const eventExtra3 = { event: 'change-ff', newFlags: newFlags[key] };
          this.logService.log(`Feature Flag ${key} changed via SSE`, 'FeatureFlagClientService', LogLevel.Debug, eventExtra3);
          const flagChange = new FeatureFlagChange(key, newFlags[key]);
          this.flagsChanged.next(flagChange);
        }
      });
    });

    this.client.on('error', (e) => {
      const eventError = { event: 'error', errorInfo: e };
      this.logService.log('FeatureFlagClientService (Error event received)', 'FeatureFlagClientService', LogLevel.Error, eventError);
    });
  }

  private initializeUser(user: FeatureFlagUser.User): FeatureFlagUser.User {
    // Check if local storage has a user already
    let userMessage = '';
    const existingUser: FeatureFlagUser.User = this.localStorageService.get(FeatureFlagConstants.FEATURE_FLAG_LOCAL_STORAGE_KEY_NAME);
    if (!existingUser) {
      // No user, create a new one with a new key
      user = user.withKey(uuidv4());
      userMessage = 'FeatureFlagClientService: init new local user';
      this.saveUser(user);
    } else {
      user = user.withKey(existingUser.key); // Only carry forward the existing key
      userMessage = 'FeatureFlagClientService: found local user ';
    }

    const userExtra = { event: 'initialize-user', user: user };
    this.logService.log(`${userMessage} (${user.key})`, 'FeatureFlagClientService', LogLevel.Debug, userExtra);
    return user;
  }

  /**
   * Saves the user to local storage.
   * @param FeatureFlagUser.User user - HA feature flag user object.
   */
  private saveUser(user: FeatureFlagUser.User) {
    const userExtra = { event: 'save-user', user: user };
    this.logService.log(`FeatureFlagClientService: saveUser (${user.key})`, 'FeatureFlagClientService', LogLevel.Debug, userExtra);
    this.localStorageService.set(FeatureFlagConstants.FEATURE_FLAG_LOCAL_STORAGE_KEY_NAME, this.user);
  }


  /**
   * TODO: refactor to helper class outside of the client service
   *
   * Helper method to map a FeatureFlagUser.User into an LDContext.
   * @param FeatureFlagUser.User user - HA feature flag user object.
   * @returns - An LDContext object.
   */
  private mapUserToLDContext(user: FeatureFlagUser.User): LDContext {
    // Map the custom HA properties
    const customProperties = {
      market: user.market,
      marketType: user.marketType,
      loyaltyNumber: user.loyaltyNumber,
      loyaltyTier: user.loyaltyTier,
      origin: user.origin,
      destination: user.destination,
      paxCountAdult: user.paxCountAdult,
      paxCountChild: user.paxCountChild,
      paxCountInfant: user.paxCountInfant,
      currency: user.currency,
      isGroupBooking: user.isGroupBooking,
      isCorporateBooking: user.isCorporateBooking,
      tripType: user.tripType,
      pnr: user.pnr,
      availableCredit: user.availableCredit,
      fullyCancelledTripCount: user.fullyCancelledTripCount,
      isNRSA: user.isNRSA,
    };

    // Map the standard LD properties + custom properties
    const ldContext: LDContext = {
      kind: 'user',
      key: user.key,
      name: user.name,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.emailAddress,
      avatar: user.avatar,
      ip: user.ipAddress,
      country: user.country,
      anonymous: user.isAnonymous,
      ...customProperties,
    };

    return ldContext;
  }

  waitUntilReady(): Promise<void> {
    return this.client?.waitUntilReady();
  }
}
