import { Injectable } from '@angular/core';
import { LoggerService, LogLevel, LogMessage } from '@hawaiianair/common';
@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private loggerService: LoggerService) { }

  log(message: string, component?: string, level?: number, extra?: any) {
    const logMessage = {
      componentId: component,
      description: message,
      level: level || LogLevel.Trace,
      extra: extra
    } as LogMessage;

    this.loggerService.log(logMessage);
  }
}
