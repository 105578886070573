import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, withLatestFrom, concatMap, tap, catchError, switchMap } from 'rxjs/operators';
import * as SessionActions from '../../actions/session.actions';
import * as FlightActions from '~app/store/actions/flight.actions';
import * as fromSession from '../../services/session/session.selectors';
import * as fromReferenceData from '~app/store/services/reference-data/reference-data.selectors';
import * as fromTrip from '../../services/trip/trip.selectors';
import * as RouterActions from '~app/store/actions/router.actions';
import * as TripActions from '~app/store/actions/trip.actions';
import { Store, select, Action } from '@ngrx/store';
import { SessionState } from '../../reducers';
import { CHECKIN_ROUTES } from '~app/store/reducers/router/checkin-route-serializer';
import { HelperService } from '~app/services/helper.service';
import { ModalsService } from '~app/modals/modals.service';
import { CartContentItems } from '~app/models/cartcontentItems.model';
import { Catalog } from '~app/models/catalog.model';
import { ALERTS, Constants } from '~app/constants/ha-constants';
import { CATALOG_ID_BAG, CATALOG_ID_SEAT_AMADEUS } from "~app/store/services/cart/cart.service";
import { FeatureFlagClientService } from "~app/services/feature-flag-client.service";
import { FeatureFlagConstants } from '~app/constants/feature-flag-constants';

@Injectable()
export class SessionEffects {
  getSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.getSession),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromSession.getSessionState))),
        map(([_, initialState]) => SessionActions.getSessionSuccess({ session: initialState }))
      ))
    )
  );

  selectedSegmentLDCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.setSessionSelectedSegment),
      concatMap(action => of(action).pipe(
          withLatestFrom(this.store.select(fromTrip.getTrip)),
          switchMap(([action, trip]) => {
            const isNRSA = trip?.results?.[0]?.isPassTravel && !trip?.results?.[0]?.isPositiveSpace;
            const user = this.featureFlagClient.user
              .withOrigin(action.value.origin)
              .withDestination(action.value.destination)
              .withIsNRSA(isNRSA);
            return from(this.featureFlagClient.identifyUser(user)).pipe(
              map(_ => {
                if (action.value.marketType === Constants.marketTypeInternational && 
                    !this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.WEB_CHECK_IN_SPA_INTERNATIONAL_ENABLED)) {
                  return SessionActions.setSessionSelectedSegmentError({ errorCode: ALERTS.restrictedDepartureAlert });
                } else {
                  return SessionActions.setSessionSelectedSegmentData(action);
                }
              })
            );
          })
        )
      )
    )
  );

  setSessionSelectedSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.setSessionSelectedSegmentData),
      concatMap(action => of(action).pipe(
        withLatestFrom(
          this.store.pipe(select(fromTrip.getTrip))
        ),
        tap(([_, trip]) => {
          if (action.value.marketType === Constants.marketTypeInternational && !this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.WEB_CHECK_IN_SPA_INTERNATIONAL_ENABLED)) {
            throw { errorCode: ALERTS.restrictedDepartureAlert };
          }

          if (!trip || !trip.results || !trip.results.length) {
            throw { error: 'No Trip Data' };
          }

          if (this.featureFlagClient.getFeatureFlagVariation(FeatureFlagConstants.WEB_CHECK_IN_SPA_TARGET_MESSAGING_MODAL_ENABLED)) {
            const outboundName = ALERTS.targetMessageAlert.find(propName => propName === `${action.value.origin}outbound`);
            const inboundName = ALERTS.targetMessageAlert.find(propName => propName === `${action.value.destination}inbound`);
            if (!!outboundName || !!inboundName) {
              this.modalsService.openTargetMessageModal(
                action.value.origin,
                action.value.destination,
                outboundName ?? inboundName
              );
            }
          }
        }),
        withLatestFrom(this.store.pipe(select(fromTrip.getTrip)),
          this.store.pipe(select(fromReferenceData.getAirportCityNm, action.value.origin)),
          this.store.pipe(select(fromReferenceData.getAirportCityNm, action.value.destination))
        ),
        concatMap(([_, trip, origin, destination]) => {
          const segmentFlights = trip.results[0].flights.entries.filter(flight =>
            action.value.details[0].flightDetails.find(flightDetail => flight.id === flightDetail.flightId));
          const isOAFlight = segmentFlights.some(flight => flight.operatedBy !== Constants.operatedByHA);
          return [
            RouterActions.setSelectedSegmentRouting({
              componentName: CHECKIN_ROUTES.ROUTE_SEGMENT_SELECTOR.component,
              segment: action.value
            }),
            SessionActions.setSessionSelectedSegmentSuccess({ segment: action.value, segmentFlights }),
            FlightActions.passengerGetAllFlights(),
            SessionActions.setSessionTripId({ name: 'tripId', value: trip.results[0].segments.tripId }),
            SessionActions.setAirportOriginLongName({
              name: 'airportOriginLongName',
              value: origin
            }),
            SessionActions.setAirportDestinationLongName({
              name: 'aiportDestinationLongName',
              value: destination
            }),
            SessionActions.setSessionOAFlightFlag({
              isOAFlight: isOAFlight
            })
          ];
        }),
        catchError(error => of(SessionActions.setSessionSelectedSegmentError(error)))
      ))
    )
  );

  setSessionSelectedSegmentError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.setSessionSelectedSegmentError),
      tap(action => {
        if (action.errorCode === ALERTS.restrictedDepartureAlert) {
          this.modalsService.openRestrictedAirportsErrorMessage({ error: action.errorCode });
        }
        else {
          this.modalsService.openGenericErrorMessage({ error: action.errorCode });
        }
      })
    ), { dispatch: false });


  setSessionOriginallyAssignedSeats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.addSessionPassengerIds, SessionActions.setSessionPassengerIds,
        SessionActions.setSessionSelectedSegmentSuccess, SessionActions.updateSessionSelectedSegment),
      switchMap(action => of(action).pipe(
        withLatestFrom(
          this.store.pipe(select(fromSession.getSessionPassengerIds)),
          this.store.pipe(select(fromSession.sessionSelectedSegment)),
          this.store.pipe(select(fromTrip.getTripPassengersList)),
          this.store.pipe(select(fromTrip.isInternational)),
        ),
        switchMap(([_, paxIds, segment, passengersList, isInternational]) => {
          const passengers = passengersList.entries.filter(entry => paxIds.find(id => entry.id === id));
          const seats = segment?.details?.flatMap(detail => {
            return detail.flightDetails.map(flight => {
              const flightDetail = detail.flightDetails.find(seatDetail => seatDetail.seatNumber === flight.seatNumber);
              return {
                paxId: detail.passengerId,
                seatId: flight.seatNumber,
                flightId: flight.flightId,
                paxName: detail.passengerName,
                seatCategory: this.helperService.setSeatCategory(flightDetail.seatCategory),
              };
            });
          });
          let assignedSeats = seats?.filter(seat => paxIds.find(paxid => paxid === seat.paxId));
          assignedSeats = assignedSeats && assignedSeats.sort((a, b) => a.paxName.firstName.localeCompare(b.paxName.firstName));

          const actions: Action[] = [
            SessionActions.setSessionOriginallyAssignedSeats({ assignedSeats }),
            SessionActions.setSelectedPassengers({ passengers })
          ];
          return actions;
        })
      ))
    )
  );

  setSessionFullTripData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.setSessionResultData),
      concatMap(action => of(action).pipe(
        withLatestFrom(
          this.store.pipe(select(fromSession.sessionResultData)),
          this.store.pipe(select(fromSession.getSessionState)),
          this.store.pipe(select(fromReferenceData.getAirportMap))
        ),
        map(([_, resultData, sessionData, airports]) => {
          const data = [];
          resultData.results[0].segments.entries.forEach(segment => {
            if (segment?.origin === sessionData.selectedSegment?.origin && segment?.destination === sessionData.selectedSegment.destination
              && segment?.departure.utcDateTime === sessionData.selectedSegment.departure.utcDateTime) {
              segment?.details.forEach(segmentDetail => {
                if (sessionData.passengerIds.includes(segmentDetail.passengerId)) {
                  segmentDetail.flightDetails.map(flight => {
                    const flightEntry = sessionData.trip2Checkin.flights.entries.find(entry => {
                      return flight.flightId === entry.id;
                    });
                    const passengerEntry = sessionData.trip2Checkin.passengers.entries.find(passenger => {
                      return passenger.id === segmentDetail.passengerId;
                    });
                    const airportData = airports[flightEntry.origin];
                    const airportOriginLongName = airportData && airportData.cityNm;
                    data.push({ ...flight, ...flightEntry, ...segmentDetail, ...passengerEntry, airportOriginLongName });
                  });
                }
              });
            }
          });
          return SessionActions.setSessionFullTripData({ name: 'fullTripData', value: data });
        })
      ))
    )
  );
  updateCartContent$ = createEffect(() =>
    this.actions$.pipe(ofType(SessionActions.updateCartContent),
      switchMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromSession.cartContentItems))),
        map(([_, cartItems]) => {
          let catalog = action.catalog.results.find(result => result.id === CATALOG_ID_BAG);
          let updatedContent = this.updateCartContent(cartItems, catalog);
          catalog = action.catalog.results.find(result => result.id === CATALOG_ID_SEAT_AMADEUS);
          updatedContent = this.updateCartContent(cartItems, catalog);
          return SessionActions.updateCartContentSuccess({ cartContentItems: updatedContent });
        }),
        catchError(error => of(SessionActions.setSessionSelectedSegmentError(error)))
      )
      ))
  );

  updateEliteStandByData$ = createEffect(() =>
    this.actions$.pipe(ofType(SessionActions.updateEliteStandByData),
      switchMap(action => of(action).pipe(
        switchMap(_ => {
          if (!action.selectedEliteStandByPassengers.length) {
            return [RouterActions.routeToSeats({ componentName: CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE_LIST.component })];
          }

          const actions: Action[] = [RouterActions.routeToSeatUpgrade({ componentName: CHECKIN_ROUTES.ROUTE_SEAT_UPGRADE_LIST.component })];
          if (action.selectedEliteStandByPassengers && !action.selectedEliteStandByPassengers.some(pax => !pax.isCheckedIn)) {
            actions.unshift(TripActions.addToEliteStandByList({ selectedEliteStandByPassengers: action.selectedEliteStandByPassengers }));
          }

          return actions;
        })
      )))
  );


  private updateCartContent(currentCartContentItems: CartContentItems[], catalog: Catalog) {
    // Do not update Cart Content from catalogs if Static Cart Content store is empty
    if (!!currentCartContentItems && currentCartContentItems.length > 0) {
      const cartContentItems = Object.assign([], currentCartContentItems);
      catalog.entries[0].products.
        filter(product => !currentCartContentItems.find(contentItem => contentItem.code === product.productId))
        .forEach(product => {
          cartContentItems.push({
            code: product.productId,
            title: product.commercialName,
            icon: 'icon-checked-bags',
            contentExists: false
          });
        });
      return cartContentItems;
    }
    else {
      return currentCartContentItems;
    }
  }

  constructor(
    private actions$: Actions,
    private store: Store<SessionState>,
    private helperService: HelperService,
    private modalsService: ModalsService,
    private featureFlagClient: FeatureFlagClientService,
  ) { }
}
