import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ComponentRendering} from "@hawaiianair/core";

@Component({
  selector: 'app-ha-datalist',
  templateUrl: './ha-datalist.component.html',
  styleUrls: ['./ha-datalist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HaDatalistComponent),
      multi: true
    }
  ]
})

export class HaDatalistComponent implements ControlValueAccessor {
  @Input() inputId = '';
  @Input() label: string;
  @Input() list = '';
  @Input() placeholder = '';
  @Input() hint = '';
  @Input() control?: UntypedFormControl = new UntypedFormControl();
  @Input() options: string[] = [];
  @Input() rendering: ComponentRendering;
  value = '';
  onChange;
  onTouched;

  writeValue(value) {
    if (this.value !== value) {
      this.value = value;
      this.control.patchValue(this.value);
      this.control.markAsTouched();
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this.onTouched = fn;
  }
}
