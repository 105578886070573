import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ComponentRendering, ContentSchema, PropertyType } from "@hawaiianair/core";
import { Subscription } from "rxjs";
import { RegexPatterns } from "~app/constants/regex-constants";
import { AdultSearch } from "~app/models/adultsearch.model";

@Component({
    selector: 'app-adult-search-card',
    templateUrl: './adult-search-card.component.html',
    styleUrls: ['./adult-search-card.component.scss']
})
@ContentSchema({
    description: 'Adult Search Card',
    name: 'AdultSearchCard',
    props: [
        { name: 'lookup', type: PropertyType.Text },
        { name: 'lastName', type: PropertyType.Text },
        { name: 'confirmationCode', type: PropertyType.Text },
    ]
})

export class AdultSearchCardComponent implements OnInit, OnDestroy {
    @Input() rendering: ComponentRendering;
    @Output() adultSearchFormIsValid: EventEmitter<AdultSearch> = new EventEmitter();
    subscriptions = new Subscription();
    adultSearchForm: UntypedFormGroup;



    ngOnInit() {
        this.adultSearchForm = new UntypedFormGroup({
            lastName: new UntypedFormControl('', [Validators.required, Validators.pattern(RegexPatterns.alphaValidation)]),
            confirmationCode: new UntypedFormControl('', {
                validators: [Validators.required, Validators.pattern('^[A-z]{6}$')]
            })
        });
        this.subscriptions.add(this.adultSearchForm.statusChanges.subscribe(formStatus => {
            if (formStatus === 'VALID' ) {
                this.sendFormData();
                } else {
                    this.adultSearchFormIsValid.emit();
                }
            })
        );
    }

    sendFormData() {
        this.adultSearchFormIsValid.emit(this.adultSearchForm.value);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
