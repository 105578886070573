import { UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

export class EmergencyContactValidator extends Validators {
  static createEmergencyContactValidator(): ValidatorFn {
    return (form: UntypedFormGroup): Validators | null => {
      const name: string = form.get('name').value;
      const phoneNumber: string = form.get('phoneNumber').get('number').value;
      const countryCode: string = form.get('phoneNumber').get('countryCode').value;

      if (!name && !phoneNumber && !countryCode) {
        form.get('name').setErrors(null);
        form.get('phoneNumber').get('countryCode').setErrors(null);
        form.get('phoneNumber').get('number').setErrors(null);
        return null;
      }

      if (name && !phoneNumber && !countryCode) {
        form.get('phoneNumber').get('number').setErrors({ 'required': true });
        form.get('phoneNumber').get('countryCode').setErrors({ 'required': true });
      }

      if (name && phoneNumber && !countryCode) {
        form.get('phoneNumber').get('countryCode').setErrors({ 'required': true });
      }

      if (name && !phoneNumber && countryCode) {
        form.get('phoneNumber').get('number').setErrors({ 'required': true });
      }

      if (!name && phoneNumber && !countryCode ) {
        form.get('name').setErrors({ 'required': true });
        form.get('phoneNumber').get('countryCode').setErrors({ 'required': true });
      }

      if (!name && phoneNumber && countryCode ) {
        form.get('name').setErrors({ 'required': true });
      }

      if (!name && !phoneNumber && countryCode) {
        form.get('name').setErrors({ 'required': true });
        form.get('phoneNumber').get('number').setErrors({ 'required': true });
      }

      form.markAllAsTouched();

      return form.valid ? null : { missingParam: true };
    }
  }
}
